import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1012.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1012.000000) scale(0.100000,-0.100000)">
        <path d="M4833 10082 c-52 -5 -38 -18 22 -20 40 -1 61 -7 79 -23 24 -21 24
-23 8 -36 -15 -12 -14 -13 15 -8 26 5 32 3 38 -14 4 -14 15 -21 31 -21 13 0
24 2 24 4 0 9 -88 96 -98 96 -6 0 -12 4 -14 8 -4 11 -63 19 -105 14z"/>
<path d="M5976 10082 c-20 -3 -47 -22 -83 -58 -29 -30 -53 -59 -53 -64 0 -18
19 -10 50 20 32 31 50 38 50 19 0 -5 4 -8 10 -4 5 3 7 12 3 20 -9 24 17 45 56
46 20 0 45 4 56 8 16 7 12 9 -20 12 -22 2 -53 3 -69 1z"/>
<path d="M5099 10060 c16 -14 37 -20 65 -19 24 1 31 4 18 6 -13 3 -26 11 -29
19 -3 9 -19 14 -42 14 l-36 -1 24 -19z"/>
<path d="M5755 10070 c-3 -5 -20 -14 -38 -19 l-32 -9 40 -1 c25 0 49 7 65 19
l25 20 -27 0 c-15 0 -30 -4 -33 -10z"/>
<path d="M4625 10000 c-10 -11 -16 -23 -13 -25 9 -9 59 17 55 29 -6 18 -23 17
-42 -4z"/>
<path d="M6228 10013 c-41 -10 -14 -29 46 -32 29 -1 29 -1 11 19 -18 20 -25
22 -57 13z"/>
<path d="M5198 9955 c-17 -20 -28 -24 -48 -20 -32 7 -80 -27 -80 -56 0 -21 10
-25 27 -8 13 13 53 2 53 -15 0 -7 -11 -23 -24 -35 -33 -32 -23 -83 19 -92 49
-11 90 -37 95 -61 4 -16 14 -24 34 -26 19 -2 34 -14 47 -37 11 -18 27 -36 36
-40 15 -5 15 -4 0 13 -22 24 -21 38 3 72 20 28 14 45 -12 35 -25 -10 -46 17
-41 53 3 17 11 32 18 32 7 0 18 7 25 15 11 13 9 20 -10 40 l-23 25 -22 -36
c-12 -20 -33 -43 -46 -50 -29 -16 -115 -18 -124 -4 -7 11 55 86 88 109 13 9
22 9 32 1 7 -6 35 -10 62 -9 54 2 73 -5 73 -26 0 -7 16 -29 35 -48 l34 -34 38
53 c42 59 59 69 95 55 16 -6 34 -5 56 5 32 13 33 12 73 -31 23 -25 45 -45 50
-45 14 0 10 22 -7 41 -25 27 -11 52 25 44 37 -8 46 7 22 36 -12 15 -31 23 -57
25 -26 2 -44 10 -53 24 -10 14 -24 20 -52 20 -22 0 -39 -4 -39 -10 0 -5 -18
-10 -40 -10 -31 0 -43 -5 -56 -25 -14 -22 -21 -25 -53 -19 -20 4 -49 16 -64
26 -17 12 -37 18 -50 15 -12 -3 -31 1 -41 9 -31 22 -73 17 -98 -11z m461 -39
c9 -11 8 -15 -5 -20 -23 -9 -54 2 -54 20 0 18 44 19 59 0z m-359 -5 c0 -13
-34 -23 -54 -15 -28 11 -18 24 19 24 19 0 35 -4 35 -9z"/>
<path d="M6328 9934 c-10 -22 -9 -27 5 -35 19 -11 23 -49 6 -66 -8 -8 -28 0
-76 33 -59 40 -123 59 -123 36 0 -11 39 -47 69 -62 14 -7 35 -25 48 -41 13
-15 33 -29 45 -31 13 -2 22 -12 25 -27 3 -20 1 -22 -11 -12 -11 9 -20 9 -35 2
-11 -6 -33 -11 -49 -11 -30 0 -50 -24 -32 -35 11 -7 14 -58 4 -68 -3 -3 -12
-3 -20 0 -11 4 -13 16 -8 49 6 43 6 44 -29 59 -19 8 -37 20 -39 27 -5 14 -28
2 -28 -16 0 -7 14 -27 30 -44 41 -44 40 -67 -2 -57 -18 4 -44 10 -57 13 -18 4
-22 2 -16 -8 6 -9 4 -11 -5 -5 -17 11 -30 -13 -30 -58 0 -28 7 -45 26 -64 l26
-26 -25 -23 c-14 -13 -27 -29 -30 -36 -2 -7 -8 -10 -12 -5 -5 4 -3 13 3 19 19
19 14 31 -23 57 -31 21 -39 22 -59 11 -17 -8 -29 -9 -42 -2 -11 6 -32 8 -47 5
-16 -3 -34 0 -44 8 -15 13 -16 10 -10 -28 4 -23 10 -48 13 -57 11 -28 -15 -17
-54 21 -32 32 -44 38 -94 41 -32 2 -65 1 -75 -2 -29 -11 4 -26 59 -26 42 0 49
-3 63 -30 8 -16 15 -39 15 -52 0 -13 6 -32 13 -43 18 -26 27 -58 37 -125 15
-101 60 -199 60 -130 0 26 47 59 250 172 19 11 41 27 48 36 8 11 24 15 47 13
29 -2 35 1 33 16 -3 42 2 53 26 53 22 0 56 32 56 52 0 24 -58 -3 -109 -49 -13
-13 -32 -23 -41 -23 -10 0 -20 -7 -24 -15 -6 -15 -36 -21 -36 -7 0 5 21 23 48
42 86 61 168 116 182 124 59 29 136 81 158 108 13 16 32 28 43 28 12 0 21 9
25 26 12 46 -10 48 -63 6 -49 -39 -104 -72 -120 -72 -4 0 -3 4 2 8 6 4 19 24
30 45 11 21 35 48 52 60 39 26 40 32 12 48 -19 10 -21 16 -14 58 12 79 6 109
-26 127 -33 19 -38 18 -51 -12z m-1 -246 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-174 -175 c-7 -3 -13 -11 -13 -19 0 -8 -4 -14 -10 -14 -15 0
-12 11 7 32 9 10 19 15 22 12 3 -4 0 -9 -6 -11z m-243 -58 c0 -14 -4 -25 -10
-25 -5 0 -10 11 -10 25 0 14 5 25 10 25 6 0 10 -11 10 -25z m-85 6 c3 -5 1
-12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-25 -91 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m84 -82 c6 -4 6 -11 -1
-20 -6 -7 -13 -25 -17 -40 -4 -18 -13 -28 -25 -28 -23 0 -36 16 -45 54 -6 24
-2 34 21 56 l28 27 14 -21 c8 -12 19 -24 25 -28z"/>
<path d="M4503 9940 c-14 -6 -21 -15 -17 -24 3 -8 1 -17 -4 -20 -6 -3 -6 -23
-1 -48 12 -61 11 -65 -12 -86 l-20 -20 31 -22 c31 -22 80 -90 80 -110 0 -10
-39 5 -50 19 -15 19 -132 102 -137 97 -10 -10 29 -86 44 -86 7 0 33 -18 57
-40 24 -22 46 -40 49 -40 3 0 26 -13 50 -30 25 -16 48 -30 52 -30 6 0 44 -26
178 -120 31 -22 44 -37 37 -44 -6 -6 -16 -4 -28 7 -10 9 -24 17 -30 17 -6 0
-27 14 -45 30 -19 17 -38 30 -42 30 -4 0 -14 8 -21 18 -10 13 -15 14 -25 5
-18 -18 14 -63 45 -63 26 0 44 -29 25 -41 -19 -12 1 -29 31 -27 16 1 37 -5 47
-13 10 -8 52 -35 93 -59 41 -23 87 -51 102 -61 15 -11 30 -19 33 -19 16 0 69
-49 88 -82 26 -47 37 -48 30 -3 -4 22 1 52 12 81 9 25 14 55 11 67 -6 23 6 73
30 120 8 16 14 38 14 49 0 11 7 32 16 48 25 41 3 77 -31 52 -26 -19 -65 -84
-66 -109 0 -15 -4 -37 -8 -48 -8 -19 -8 -19 -20 4 -13 23 -7 71 10 82 5 3 13
19 18 36 l9 31 -69 7 c-38 4 -69 11 -69 16 0 15 -58 10 -67 -5 -4 -8 -16 -15
-25 -17 -16 -4 -17 -6 -3 -33 25 -48 16 -53 -20 -12 -19 21 -35 40 -35 43 0 3
11 18 25 35 34 41 35 99 1 122 -17 10 -27 12 -31 5 -4 -6 -14 -8 -22 -4 -9 3
-24 -2 -34 -11 -17 -15 -19 -15 -25 -1 -10 26 0 51 24 62 15 7 22 18 22 37 0
35 -9 35 -65 -3 -32 -21 -49 -28 -58 -21 -7 5 -32 13 -56 17 -30 5 -45 13 -48
26 -3 10 -9 19 -14 19 -5 0 -9 5 -9 10 0 7 7 7 21 0 16 -9 25 -8 40 6 10 9 19
23 19 30 0 17 48 54 70 54 10 0 28 9 41 19 l24 19 -26 7 c-31 8 -88 -14 -121
-45 -13 -12 -35 -28 -50 -36 -23 -12 -27 -12 -32 2 -10 26 -7 53 8 61 8 4 16
17 18 28 2 15 -3 21 -22 22 -14 1 -35 -2 -47 -7z m87 -245 c0 -8 -4 -17 -10
-20 -6 -4 -10 5 -10 20 0 15 4 24 10 20 6 -3 10 -12 10 -20z m140 -57 c0 -12
-38 -10 -45 2 -4 6 -4 18 -1 27 6 14 9 14 26 -2 11 -10 20 -22 20 -27z m20
-118 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m30 -30 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m230 -24 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0
10 -6 10 -14z m70 -148 c39 -28 51 -83 24 -103 -11 -8 -30 -15 -43 -15 -18 0
-22 4 -18 20 3 11 2 20 -2 20 -4 0 -13 11 -19 24 -9 19 -8 27 4 36 8 7 14 19
14 26 0 19 6 18 40 -8z"/>
<path d="M6300 9940 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4358 9889 c-24 -14 -26 -129 -3 -129 8 0 15 4 15 9 0 5 12 14 28 19
15 6 21 11 15 11 -9 1 -13 18 -13 51 0 52 -8 59 -42 39z"/>
<path d="M6495 9870 c3 -5 -1 -25 -9 -44 -16 -37 -13 -46 16 -46 10 0 21 -7
24 -15 4 -8 12 -15 20 -15 22 0 19 99 -3 116 -21 15 -57 19 -48 4z"/>
<path d="M4988 9848 c8 -8 17 -8 34 0 22 10 21 11 -11 11 -27 1 -32 -2 -23
-11z"/>
<path d="M5875 9850 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10 -15
0 -24 -4 -20 -10z"/>
<path d="M5548 9837 c-37 -14 -36 -41 2 -64 24 -14 30 -24 30 -50 0 -28 -4
-33 -22 -33 -40 0 -52 -14 -37 -41 7 -13 12 -36 11 -51 -1 -16 0 -28 2 -28 19
0 36 15 36 32 0 12 10 24 28 30 15 6 36 25 47 42 15 22 35 35 73 46 63 19 65
36 3 32 -27 -3 -58 3 -82 14 -34 15 -39 21 -39 51 0 35 -7 38 -52 20z"/>
<path d="M5426 9691 c-3 -5 0 -11 7 -13 17 -7 38 2 31 13 -7 12 -31 12 -38 0z"/>
<path d="M5177 9583 c-15 -14 -6 -23 23 -23 32 0 40 14 14 24 -20 8 -29 8 -37
-1z"/>
<path d="M5665 9570 c-4 -6 8 -10 30 -10 22 0 34 4 30 10 -3 6 -17 10 -30 10
-13 0 -27 -4 -30 -10z"/>
<path d="M5380 9551 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M5505 9541 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4215 9521 c-19 -8 -18 -9 7 -16 32 -8 59 -4 59 10 0 13 -40 16 -66
6z"/>
<path d="M4362 9518 c5 -15 50 -22 86 -13 21 5 23 8 11 16 -22 14 -101 11 -97
-3z"/>
<path d="M6445 9520 c-3 -6 0 -13 8 -16 24 -9 80 0 85 14 5 15 -84 17 -93 2z"/>
<path d="M6620 9521 c0 -17 20 -23 48 -16 20 5 23 8 11 16 -19 12 -59 12 -59
0z"/>
<path d="M4500 9490 c0 -5 14 -10 31 -10 17 0 28 4 24 10 -3 6 -17 10 -31 10
-13 0 -24 -4 -24 -10z"/>
<path d="M6336 9491 c-6 -10 33 -20 52 -13 7 2 12 8 12 13 0 12 -57 12 -64 0z"/>
<path d="M4590 9460 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M4245 9323 c-43 -9 -49 -13 -37 -25 8 -8 9 -15 1 -25 -9 -11 1 -13
52 -13 35 0 107 -7 159 -15 113 -17 120 -18 120 -5 0 5 -8 7 -17 4 -16 -5 -16
-4 1 10 10 8 24 17 30 20 30 12 -29 26 -115 26 -66 0 -106 5 -125 15 -30 15
-35 16 -69 8z"/>
<path d="M6585 9315 c-23 -8 -76 -15 -123 -15 -46 0 -92 -5 -102 -10 -18 -10
-18 -11 6 -37 l25 -27 66 14 c36 7 111 16 167 20 83 5 95 8 67 15 -33 8 -34 9
-11 17 14 5 20 12 14 17 -18 17 -70 20 -109 6z"/>
<path d="M4604 9255 c22 -17 46 -20 46 -6 0 10 -25 21 -48 21 -15 -1 -14 -3 2
-15z"/>
<path d="M6195 9239 c-83 -31 -122 -59 -111 -78 4 -6 16 -11 27 -11 11 0 17 5
14 10 -3 6 0 10 7 11 43 2 72 10 87 22 9 8 22 12 30 9 10 -3 12 0 8 11 -5 12
2 19 25 25 38 10 50 32 16 31 -12 0 -59 -14 -103 -30z"/>
<path d="M4657 9219 c-5 -19 -3 -20 29 -15 32 7 50 -7 22 -17 -7 -2 5 -10 27
-17 29 -9 40 -10 43 -1 2 6 11 11 19 11 29 0 -5 27 -42 34 -19 4 -35 11 -35
16 0 6 -13 10 -29 10 -21 0 -30 -6 -34 -21z"/>
<path d="M5224 9230 c-16 -6 -20 -15 -17 -34 7 -32 20 -41 38 -26 10 9 16 7
24 -7 10 -17 12 -17 23 2 34 56 -3 91 -68 65z"/>
<path d="M5364 9232 c-18 -12 -23 -50 -8 -63 11 -9 17 -9 28 0 11 9 15 9 19
-1 7 -21 40 4 42 33 3 28 -10 39 -45 39 -14 0 -30 -4 -36 -8z"/>
<path d="M5494 9233 c-16 -11 -11 -51 9 -70 11 -11 17 -13 17 -5 0 7 7 12 15
12 8 0 15 -6 15 -12 0 -7 7 -2 16 10 8 12 14 32 12 45 -2 17 -10 23 -38 25
-19 1 -40 -1 -46 -5z"/>
<path d="M5649 9233 c-31 -3 -36 -8 -36 -28 0 -34 23 -52 47 -38 13 9 20 9 29
0 17 -17 33 5 29 39 -3 31 -10 33 -69 27z"/>
<path d="M3933 9203 c-6 -4 -13 -22 -15 -38 -2 -22 -9 -31 -25 -33 -13 -2 -28
-11 -35 -20 -7 -9 -26 -19 -42 -20 -16 -2 -35 -9 -42 -15 -7 -7 -31 -25 -54
-42 -23 -16 -53 -46 -68 -65 -26 -34 -27 -40 -27 -156 1 -132 4 -141 74 -200
l32 -27 -22 42 c-27 53 -14 73 24 37 20 -19 42 -26 94 -31 37 -3 75 -10 83
-16 8 -5 27 -17 42 -27 16 -9 28 -22 28 -28 0 -7 -22 -23 -49 -37 -43 -23 -53
-25 -80 -14 -26 10 -34 8 -60 -11 -17 -12 -43 -22 -59 -22 -15 0 -33 -5 -40
-12 -15 -15 -16 -55 -1 -64 8 -4 7 -9 -2 -15 -8 -4 -17 -1 -25 9 -10 14 -16
14 -38 4 -15 -7 -26 -20 -26 -31 0 -11 -5 -23 -11 -27 -11 -7 -9 -28 11 -141
10 -54 9 -65 -6 -88 -28 -42 -71 -85 -86 -85 -35 0 -52 53 -20 64 9 3 28 13
42 21 19 11 21 15 8 15 -25 0 -23 38 3 59 21 17 21 17 -16 13 l-37 -4 4 43 3
43 -52 2 c-29 0 -63 8 -77 17 -35 24 -38 22 -35 -22 3 -33 -1 -43 -23 -60 -14
-12 -38 -21 -52 -21 -15 0 -31 -8 -38 -20 -10 -15 -24 -20 -55 -20 -24 0 -43
-4 -43 -10 0 -5 6 -10 14 -10 14 0 15 -35 2 -48 -7 -6 -36 24 -36 38 0 6 -5 8
-11 4 -6 -3 -9 -19 -7 -34 3 -22 -1 -31 -19 -39 -29 -13 -29 -41 -2 -97 l22
-43 -22 -43 c-18 -37 -21 -40 -22 -18 -1 14 -1 30 0 36 1 6 -10 16 -24 22 -15
7 -25 20 -25 32 0 19 -5 21 -67 18 -63 -3 -68 -5 -65 -24 2 -15 -4 -23 -19
-27 -24 -7 -20 -34 5 -29 20 4 21 -24 1 -32 -8 -3 -15 -12 -15 -19 0 -8 -11
-19 -25 -25 -14 -6 -25 -16 -25 -22 0 -5 -5 -10 -11 -10 -7 0 -22 -11 -35 -25
-17 -19 -33 -25 -64 -25 -31 0 -40 -4 -41 -17 0 -11 -3 -13 -6 -5 -12 29 -21
7 -27 -68 -6 -77 -5 -82 20 -116 30 -39 26 -54 -8 -32 -33 21 -86 27 -99 11
-7 -7 -32 -13 -60 -13 -45 0 -49 -2 -49 -24 0 -13 -6 -26 -14 -29 -9 -3 -15
-29 -18 -74 -6 -79 -28 -123 -63 -123 -12 0 -30 -9 -40 -20 -10 -11 -28 -20
-40 -20 -20 0 -21 -5 -23 -88 l-1 -89 37 -9 c31 -8 38 -14 40 -39 2 -16 9 -36
14 -43 15 -18 5 -24 -24 -13 -15 5 -30 5 -41 0 -15 -9 -17 -5 -17 26 0 31 -3
35 -25 35 -14 0 -25 -4 -25 -9 0 -5 -10 -13 -21 -16 -20 -7 -18 -10 26 -49 45
-40 47 -45 41 -78 -3 -19 -16 -44 -27 -54 -17 -15 -19 -26 -14 -61 6 -42 6
-43 -39 -70 -25 -15 -46 -33 -46 -38 0 -6 -17 -27 -38 -48 -33 -33 -43 -37
-95 -38 l-57 -2 0 -67 c0 -67 0 -68 -44 -107 -25 -22 -57 -55 -72 -72 -25 -30
-26 -33 -8 -45 14 -11 18 -24 15 -60 -1 -28 2 -46 8 -46 6 0 11 8 12 18 4 83
1 77 39 75 72 -3 121 -14 117 -26 -4 -14 -70 -10 -76 5 -7 19 -30 -30 -37 -79
-6 -47 -9 -51 -50 -72 -25 -11 -58 -21 -75 -21 -29 0 -31 -2 -27 -30 3 -21 -1
-35 -14 -47 -22 -20 -24 -66 -3 -83 8 -7 15 -20 15 -29 0 -9 7 -25 15 -35 30
-39 19 -74 -45 -144 -63 -70 -72 -98 -43 -127 22 -23 27 -125 6 -140 -12 -9
-9 -17 17 -53 29 -41 52 -52 65 -32 9 14 27 12 22 -2 -2 -7 -10 -13 -18 -13
-11 0 -14 -22 -14 -111 0 -93 3 -115 18 -131 10 -11 17 -35 17 -57 0 -42 41
-212 65 -270 9 -22 14 -50 11 -63 -4 -14 3 -43 18 -76 37 -79 41 -122 17 -154
-26 -32 -26 -51 -1 -78 19 -20 18 -65 -1 -65 -15 0 -10 -31 11 -76 21 -42 17
-82 -9 -99 -6 -3 -11 -31 -11 -62 0 -44 -5 -61 -24 -84 -14 -16 -30 -54 -36
-86 -7 -32 -16 -68 -21 -81 -13 -32 -4 -42 35 -42 27 0 41 -8 70 -42 l37 -42
-7 -107 -7 -108 34 -14 c19 -8 41 -22 49 -31 8 -10 24 -22 37 -27 12 -4 25
-20 29 -34 4 -18 13 -25 30 -25 17 0 24 -6 24 -20 0 -11 -7 -20 -15 -20 -8 0
-15 5 -15 11 0 5 -4 8 -9 4 -13 -7 -25 -107 -16 -134 5 -15 15 -21 38 -21 18
0 39 -5 47 -10 18 -12 47 -10 -283 -15 l-259 -3 -55 38 c-30 22 -60 41 -66 43
-9 3 -9 8 1 19 11 13 8 19 -20 37 -18 11 -44 28 -57 38 -13 10 -45 18 -70 19
-63 3 -61 2 -61 17 0 8 -9 22 -20 32 -34 30 -26 68 20 99 22 14 42 26 45 26 4
0 14 7 23 16 12 13 28 15 68 11 53 -5 76 3 86 33 5 12 -2 15 -36 12 l-41 -3
35 23 c19 12 40 27 45 34 6 6 25 21 43 33 17 12 32 29 32 37 0 8 6 17 14 20 8
3 20 19 26 35 7 16 16 29 21 29 5 0 9 6 9 13 0 8 11 24 24 36 13 12 26 37 29
54 3 18 10 55 16 82 32 161 35 196 23 329 -16 186 -57 304 -148 426 -68 90
-231 230 -268 230 -10 0 -26 5 -37 11 -49 26 -260 68 -341 69 -30 0 -35 -3
-31 -17 2 -10 23 -27 46 -37 23 -11 52 -30 63 -41 16 -16 40 -24 83 -28 34 -4
72 -14 85 -22 13 -9 30 -13 38 -10 9 4 22 0 29 -8 8 -8 31 -17 52 -21 55 -10
186 -146 233 -240 19 -39 34 -77 34 -85 0 -9 7 -29 15 -45 10 -20 15 -59 15
-131 0 -90 -3 -110 -25 -153 -32 -65 -33 -124 0 -194 27 -59 33 -134 12 -171
-16 -30 -80 -111 -90 -115 -4 -2 -13 -9 -20 -16 -6 -7 -30 -24 -52 -39 -46
-31 -78 -35 -73 -9 1 9 -1 23 -6 31 -6 10 -1 20 17 33 47 34 97 162 97 246 0
66 -27 178 -51 213 -23 33 -98 69 -144 69 -21 0 -35 5 -36 13 0 6 -4 2 -9 -10
-6 -15 -21 -24 -47 -29 -36 -7 -37 -8 -13 -16 29 -10 117 -129 135 -182 21
-61 26 -121 15 -167 -6 -24 -15 -60 -19 -79 -4 -19 -19 -57 -32 -85 -21 -43
-30 -52 -74 -67 -54 -19 -67 -15 -59 20 4 14 14 22 29 22 24 0 62 35 47 44 -5
3 -26 5 -46 4 -31 -1 -36 -5 -36 -25 0 -12 -4 -23 -10 -23 -5 0 -10 -8 -10
-18 0 -38 -125 -97 -154 -73 -8 7 -20 11 -28 10 -42 -5 -45 18 -11 84 13 26
13 31 -3 48 -9 10 -37 30 -61 44 -33 20 -51 24 -77 20 -28 -6 -36 -3 -54 19
-23 30 -50 34 -59 9 -3 -10 -12 -25 -20 -34 -11 -13 -11 -21 4 -50 9 -19 26
-43 38 -54 12 -11 25 -28 28 -38 4 -9 15 -20 24 -24 10 -4 28 -17 40 -30 12
-13 31 -23 41 -23 11 0 25 -7 32 -15 7 -8 25 -15 40 -15 15 0 33 -7 40 -15 7
-8 27 -15 46 -15 18 0 36 -5 39 -10 8 -12 52 -13 59 -1 3 5 -4 11 -16 14 -22
6 -22 6 -3 27 23 25 61 23 59 -3 -2 -13 4 -18 20 -16 11 0 28 1 36 0 13 -1 13
-2 0 -11 -12 -8 -11 -10 6 -10 25 0 54 -29 54 -55 0 -10 -9 -33 -20 -51 -17
-27 -20 -46 -18 -116 l3 -83 44 -24 c24 -13 60 -27 80 -31 20 -4 50 -10 66
-14 17 -3 23 -3 15 1 -8 3 -21 14 -28 25 -7 10 -19 18 -27 18 -22 0 -85 65
-85 88 0 34 16 35 88 7 47 -19 79 -40 108 -71 24 -26 50 -44 62 -44 12 0 22
-4 22 -10 0 -5 -3 -10 -7 -10 -5 0 -35 -5 -68 -10 -167 -29 -155 -29 -206 -4
-26 14 -65 24 -88 24 -30 0 -43 5 -51 19 -8 16 -6 23 12 35 18 13 19 16 5 22
-21 8 -38 -4 -35 -24 2 -10 -4 -17 -14 -17 -10 0 -25 -7 -33 -16 -14 -14 -14
-18 5 -42 11 -14 33 -27 48 -29 51 -6 31 -67 -23 -68 -11 0 -33 -7 -49 -15
-16 -9 -31 -14 -33 -11 -2 2 -43 -7 -91 -21 -48 -14 -109 -28 -137 -33 -27 -4
-41 -8 -30 -10 13 -1 8 -5 -15 -10 -19 -5 -54 -13 -78 -19 -24 -6 -47 -9 -53
-6 -5 4 -9 1 -9 -4 0 -6 -21 -11 -47 -11 -27 0 -66 -5 -87 -10 -22 -5 -124
-14 -228 -21 -103 -7 -188 -16 -188 -20 0 -12 317 -22 473 -15 189 9 197 7
197 -50 0 -32 -4 -44 -15 -44 -11 0 -15 -11 -15 -40 0 -22 5 -40 10 -40 6 0
10 -18 10 -40 0 -36 -2 -40 -25 -40 -22 0 -25 -4 -25 -40 0 -36 -2 -40 -25
-40 -22 0 -25 -4 -25 -40 0 -29 4 -40 15 -40 12 0 15 -16 15 -80 0 -64 -3 -80
-15 -80 -12 0 -15 -16 -15 -80 0 -64 -3 -80 -15 -80 -12 0 -15 -22 -17 -117
-3 -116 -3 -117 -28 -124 l-25 -6 25 -11 c24 -10 24 -11 3 -11 -17 -1 -23 -7
-23 -25 0 -15 7 -26 17 -29 17 -4 17 -5 1 -6 -10 0 -18 -4 -18 -9 0 -10 -58
-39 -90 -45 -14 -3 -53 -12 -86 -21 -34 -8 -108 -22 -165 -31 -57 -9 -112 -19
-121 -21 -29 -8 -21 -34 10 -35 15 -1 36 -2 47 -2 27 -2 222 14 260 20 17 3
46 6 65 7 19 1 48 7 64 14 17 7 46 11 65 9 35 -3 34 -4 -6 -5 -24 -1 -43 -6
-43 -12 0 -5 3 -10 8 -10 19 0 163 33 217 50 95 30 261 63 279 57 23 -9 20
-29 -25 -162 -37 -107 -41 -127 -37 -190 3 -38 13 -88 23 -110 20 -44 73 -105
90 -105 7 0 30 -12 51 -28 41 -28 46 -31 124 -73 62 -34 305 -140 421 -183
174 -65 922 -251 1269 -315 508 -94 1100 -160 1635 -181 127 -5 291 -15 365
-21 188 -17 1299 -18 1525 -1 94 7 298 19 455 28 355 18 498 29 670 48 284 32
538 67 710 96 118 20 387 71 420 80 90 22 478 115 505 121 17 4 84 21 150 38
66 18 161 43 210 56 192 50 534 201 704 310 39 25 75 45 79 45 4 0 18 16 31
35 65 95 68 191 11 359 -43 126 -46 141 -29 151 13 9 115 -10 239 -43 118 -31
173 -42 182 -36 5 3 8 0 8 -5 0 -6 4 -11 8 -11 8 0 12 21 13 68 2 82 -14 1028
-17 1031 -2 2 -5 -196 -6 -440 l-1 -444 -12 325 c-11 313 -12 319 -15 160 l-4
-165 -6 165 c-3 91 -7 146 -8 123 -1 -24 -6 -43 -11 -43 -5 0 -12 72 -16 160
-4 88 -11 160 -16 160 -5 0 -9 18 -9 40 0 38 -2 40 -30 40 -16 0 -30 5 -30 10
0 9 21 14 42 11 3 0 31 -1 62 -2 33 -1 56 -7 56 -13 1 -6 7 1 15 16 20 37 4
74 -33 76 -15 1 -65 8 -112 16 -74 12 -79 14 -40 16 l45 2 -45 9 c-25 5 -61
14 -80 19 -19 5 -82 21 -140 35 -58 14 -114 29 -125 34 -11 5 -69 21 -130 36
-149 35 -176 45 -169 57 4 6 3 9 -2 5 -9 -5 -153 8 -206 19 -36 7 -36 24 0 24
15 1 42 9 60 19 30 15 32 19 18 30 -18 15 -22 76 -6 86 13 8 13 48 0 61 -6 6
-13 33 -16 60 -5 40 -2 54 16 75 19 23 26 25 61 19 38 -7 39 -7 24 10 -23 26
-1 36 32 15 18 -12 22 -20 14 -28 -17 -17 -13 -24 16 -30 16 -3 32 1 40 9 8 8
27 14 42 14 16 0 34 7 41 15 7 8 27 15 45 15 18 0 38 7 45 15 7 8 19 15 27 15
7 0 47 33 88 74 68 67 75 77 75 113 0 28 -7 46 -23 61 l-23 22 -24 -25 c-19
-21 -29 -24 -61 -19 -29 5 -44 1 -67 -15 -17 -11 -33 -21 -37 -21 -12 0 -44
-44 -45 -61 0 -9 6 -29 14 -44 19 -37 11 -53 -34 -66 -56 -17 -129 10 -164 59
-14 21 -26 47 -26 58 0 16 -7 20 -37 21 -21 1 -42 -1 -46 -4 -12 -7 23 -43 43
-43 19 0 40 -19 40 -36 0 -19 -31 -21 -73 -5 -32 12 -42 24 -71 85 -76 160
-67 300 26 408 13 14 30 40 40 56 9 17 27 32 40 35 22 4 22 4 -3 6 -15 0 -32
8 -39 16 -24 29 -148 8 -193 -32 -44 -41 -74 -163 -64 -269 7 -68 39 -156 70
-189 35 -37 46 -60 40 -83 -5 -20 -10 -22 -36 -16 -17 3 -35 10 -41 16 -6 5
-25 19 -43 31 -46 33 -105 109 -127 163 l-19 48 27 80 c28 86 33 152 15 206
-9 26 -9 34 2 42 11 7 9 8 -5 4 -11 -4 -23 -1 -28 6 -11 19 -10 196 2 203 6 3
10 19 10 34 0 15 7 36 15 47 8 10 15 29 15 40 0 12 7 27 15 34 8 7 15 20 15
29 0 10 11 28 25 41 14 13 25 29 25 35 0 21 113 120 160 140 25 11 51 26 59
33 9 9 27 12 47 9 19 -3 40 0 51 8 10 8 44 17 76 21 31 4 57 10 57 14 0 8 50
42 103 68 40 21 35 42 -10 42 -88 -1 -313 -51 -410 -92 -67 -28 -207 -161
-266 -250 -82 -125 -114 -229 -127 -412 -9 -115 -8 -147 5 -189 8 -28 15 -74
15 -103 0 -29 5 -56 10 -59 6 -3 10 -17 10 -31 0 -30 17 -66 37 -77 7 -4 20
-23 27 -42 8 -18 23 -42 33 -52 11 -11 26 -28 34 -39 8 -11 43 -41 79 -68 36
-26 55 -45 43 -41 -13 3 -29 2 -38 -2 -13 -8 -13 -11 1 -31 14 -20 21 -22 59
-16 36 5 54 1 100 -22 62 -31 105 -69 105 -90 0 -8 -11 -24 -25 -36 -14 -12
-25 -27 -25 -34 0 -14 -28 -27 -42 -18 -17 11 -96 -17 -119 -42 -12 -13 -28
-24 -36 -24 -8 0 -13 -12 -13 -30 0 -20 -5 -30 -15 -30 -8 0 -15 -4 -15 -9 0
-24 -89 -50 -210 -60 -141 -12 -314 -48 -440 -92 -47 -17 -102 -32 -123 -33
-34 -3 -38 -1 -35 18 2 12 11 22 24 24 12 2 26 13 31 25 6 12 23 29 39 37 16
8 45 27 64 42 19 16 40 28 47 28 10 0 13 27 13 105 0 103 1 106 24 115 14 5
37 19 51 32 14 13 36 25 48 28 19 5 22 13 23 60 2 76 -3 88 -34 86 -39 -2 -42
17 -5 34 18 8 33 21 33 28 0 17 79 102 95 102 7 0 18 7 25 15 7 8 22 15 35 15
12 0 24 3 27 6 11 11 -42 129 -68 150 -19 16 -24 27 -19 45 4 16 -3 51 -20 95
-27 71 -28 131 -3 178 7 13 7 31 -1 58 -10 33 -10 44 3 63 20 31 20 50 0 85
-16 26 -16 30 10 84 23 49 26 62 17 88 -9 25 -8 35 3 47 21 24 28 63 17 94 -9
23 -4 41 29 118 22 49 40 104 40 121 0 17 8 45 19 62 20 33 46 150 36 165 -3
6 6 35 20 67 14 31 25 58 25 60 0 2 -9 4 -21 4 -19 0 -20 4 -14 53 4 28 13 59
21 68 8 8 14 25 14 37 0 11 5 24 11 28 6 4 -10 27 -43 60 l-53 53 -6 78 c-4
62 -2 84 12 108 12 23 15 46 11 98 l-4 67 -33 0 c-38 0 -95 18 -114 36 -12 11
-9 18 17 49 18 20 32 45 32 57 0 12 7 29 15 38 20 22 10 80 -14 80 -9 0 -37
20 -63 44 -45 42 -46 45 -53 118 l-7 75 -39 12 c-22 7 -54 24 -72 40 -34 29
-76 48 -117 53 -22 3 -26 9 -30 48 -3 25 -10 54 -16 64 -11 18 -14 17 -53 -15
-22 -19 -41 -37 -41 -41 0 -7 -30 -45 -67 -84 -46 -50 -108 -16 -79 43 8 15
19 41 25 56 6 15 19 30 28 33 16 5 55 22 133 58 20 9 26 20 28 54 3 42 3 42
-32 42 -38 0 -56 14 -30 24 9 3 33 6 55 6 21 0 39 4 39 9 0 5 10 12 23 16 l22
8 -22 4 c-38 8 -63 76 -63 176 l0 87 -35 0 c-22 0 -38 6 -41 15 -4 8 -15 15
-26 15 -11 0 -27 4 -36 9 -10 4 -31 4 -49 -1 -28 -7 -33 -13 -33 -39 0 -24 -4
-30 -17 -27 -14 2 -18 14 -19 45 -2 38 1 44 32 62 l35 21 -7 67 c-7 73 -28
105 -71 111 -12 1 -45 14 -72 27 -49 25 -49 25 -56 85 -5 41 -11 60 -21 60 -9
0 -14 11 -14 28 0 33 -37 63 -51 40 -5 -7 -9 -8 -9 -3 0 13 -87 38 -101 30 -5
-4 -9 -22 -9 -41 0 -28 -4 -34 -21 -34 -11 0 -29 -5 -41 -11 -11 -6 -22 -9
-24 -6 -8 7 38 101 53 110 18 11 17 76 -2 83 -9 4 -15 19 -15 38 0 41 -44 69
-90 56 -20 -6 -32 -5 -37 3 -5 7 -21 19 -38 27 -16 9 -39 29 -50 45 -11 17
-29 32 -40 33 -33 6 -39 19 -21 46 20 31 20 32 0 40 -8 3 -22 -1 -31 -9 -16
-17 -33 -12 -33 9 0 16 -31 26 -82 26 -33 0 -39 4 -52 34 -8 18 -19 37 -25 40
-6 4 -11 16 -11 27 0 20 -16 24 -38 11 -7 -5 -10 -16 -6 -27 6 -15 3 -17 -19
-13 -25 5 -27 3 -27 -30 0 -26 -3 -33 -11 -25 -10 10 -18 6 -87 -55 -22 -19
-24 -25 -12 -33 8 -5 29 -8 47 -6 20 2 34 -2 37 -10 8 -21 -39 -158 -55 -161
-9 -1 -21 10 -28 25 -8 15 -22 44 -32 64 -16 30 -17 42 -8 68 10 28 9 35 -12
57 -12 13 -28 24 -35 24 -7 0 -20 7 -29 16 -15 15 -15 17 3 29 10 8 48 23 85
35 64 21 66 22 55 46 -14 30 -61 59 -109 68 -20 4 -47 16 -60 27 l-24 20 -17
-23 c-38 -49 -39 -50 -97 -46 -31 2 -59 8 -63 13 -15 19 -8 78 13 111 22 36
48 43 73 19 10 -11 36 -15 90 -15 42 0 76 4 76 8 0 4 -45 14 -100 21 -101 12
-211 33 -242 46 -25 10 -22 34 5 44 12 5 30 18 40 29 13 14 37 21 88 26 88 9
98 13 110 42 13 37 32 29 27 -11 -2 -20 0 -35 7 -35 20 1 60 46 61 70 1 14 5
55 9 92 11 99 -5 147 -73 214 -30 29 -63 56 -73 59 -11 4 -19 10 -19 15 0 4
-15 11 -34 14 -19 4 -39 13 -46 21 -7 8 -20 15 -29 15 -12 0 -21 12 -27 38 -9
36 -10 37 -54 35 -25 -1 -75 -2 -112 -3 -37 -1 -87 -7 -110 -14 l-43 -13 78
-17 c42 -10 77 -21 77 -25 0 -4 26 -18 58 -31 48 -20 110 -59 159 -100 7 -6
16 -10 19 -9 14 2 41 -54 36 -74 -5 -19 -7 -17 -17 12 -6 18 -23 40 -37 49
-25 15 -28 15 -47 -2 -11 -10 -24 -15 -30 -12 -6 4 -18 -2 -28 -14 -20 -23
-22 -23 -43 -10 -13 9 -10 15 18 38 58 48 9 89 -51 42 l-25 -20 -29 34 c-38
47 -67 51 -71 11 -5 -55 -9 -58 -39 -35 -16 12 -30 31 -33 43 -9 33 -98 37
-141 6 -28 -19 -29 -23 -15 -38 9 -10 16 -27 16 -39 0 -16 6 -22 21 -22 18 0
20 -4 14 -30 -5 -24 -1 -37 19 -61 21 -25 24 -34 15 -45 -9 -11 -15 -11 -25
-3 -18 15 -24 -2 -21 -58 3 -55 31 -60 52 -9 17 40 75 77 75 48 0 -9 -9 -32
-20 -50 -17 -27 -18 -34 -6 -38 8 -3 30 5 48 19 28 21 35 23 46 11 19 -20 14
-50 -10 -68 -16 -12 -19 -18 -11 -22 8 -4 11 -15 7 -30 -7 -27 6 -41 64 -69
34 -17 42 -42 17 -51 -8 -3 -34 3 -58 15 -24 12 -49 21 -55 21 -7 0 -12 15
-12 38 0 37 -22 96 -40 107 -5 3 -15 -3 -22 -13 -21 -30 -51 -42 -72 -30 -10
6 -40 8 -66 5 -38 -5 -52 -2 -68 14 -12 10 -25 19 -30 19 -5 0 -15 6 -21 14
-17 20 18 30 75 21 29 -4 44 -2 44 4 0 6 -15 11 -33 11 -20 0 -39 8 -50 20
-22 26 -47 25 -76 -1 -31 -29 -25 -74 21 -176 6 -12 7 -27 2 -35 -5 -7 -9 -38
-9 -68 0 -49 -2 -55 -22 -58 -16 -2 -27 7 -45 37 -19 35 -28 41 -55 41 -18 0
-33 -4 -33 -10 0 -5 -18 -15 -40 -21 -35 -11 -43 -10 -56 3 -10 10 -14 24 -9
37 5 18 14 21 61 21 41 0 54 4 54 15 0 8 -4 16 -10 16 -20 3 -25 4 -37 12 -18
11 -16 52 2 67 12 10 18 10 30 0 13 -10 19 -10 35 5 11 10 20 24 20 30 0 7 7
18 15 25 26 22 18 68 -17 101 -18 16 -43 32 -55 35 -21 6 -21 7 -7 29 18 27
87 44 108 26 12 -10 26 -5 26 9 0 4 -25 10 -55 14 -62 7 -78 1 -133 -48 -54
-48 -192 -77 -192 -41 0 28 -27 27 -70 -1 l-42 -28 -19 23 c-11 13 -19 31 -19
41 0 10 -7 23 -15 30 -8 7 -15 24 -15 37 0 21 -2 23 -15 13 -8 -7 -24 -10 -35
-7 -26 7 -35 -9 -17 -28 15 -15 12 -55 -3 -55 -5 0 -12 9 -15 20 -7 23 -60 54
-76 44 -6 -3 -8 -12 -5 -20 8 -20 -11 -27 -22 -7 -9 15 -16 16 -62 5 -28 -7
-69 -12 -91 -12 -21 0 -39 -4 -39 -10 0 -5 9 -10 21 -10 11 0 17 -4 14 -10 -3
-6 1 -7 9 -4 12 5 16 -1 16 -23 0 -16 -6 -37 -12 -46 -14 -18 -52 -27 -55 -13
-2 16 -33 27 -53 21 -11 -4 -20 -2 -20 4 0 6 -19 14 -42 17 -36 6 -39 9 -24
20 14 10 16 21 11 58 -4 25 -11 46 -16 46 -4 0 -17 10 -29 22 l-20 22 0 -45
c0 -57 -19 -93 -50 -97 -60 -8 -99 -23 -105 -42 -4 -11 -15 -20 -26 -20 -11 0
-19 -7 -19 -16 0 -13 3 -14 14 -5 20 17 43 3 50 -30 3 -16 12 -29 20 -29 17 0
25 -10 36 -50 8 -28 7 -30 -15 -24 -20 5 -23 3 -18 -16 4 -14 13 -21 27 -19
42 3 46 0 46 -42 l0 -42 -36 6 c-22 4 -45 17 -59 34 -23 26 -25 26 -43 10 -10
-9 -24 -17 -30 -17 -7 0 -25 -14 -41 -32 -34 -39 -61 -33 -61 12 0 45 17 71
43 68 35 -5 29 16 -8 27 -28 9 -32 13 -24 32 11 30 45 46 81 39 26 -5 29 -3
23 12 -4 9 -9 32 -12 50 -4 23 -11 32 -25 32 -11 0 -42 21 -69 47 -28 26 -68
55 -89 65 -21 10 -42 23 -45 28 -3 6 -40 10 -81 10 -65 0 -74 -2 -74 -18 0
-29 11 -34 37 -16 50 32 172 -6 148 -46 -8 -12 -82 -13 -89 -1 -3 5 -17 11
-31 14 -25 4 -26 3 -19 -27 8 -37 15 -42 71 -51 28 -4 43 -11 43 -21 0 -8 9
-14 20 -14 11 0 20 -4 20 -10 0 -10 -5 -12 -32 -14 -6 -1 -27 -14 -45 -31 -26
-23 -30 -31 -19 -37 8 -5 26 -3 41 4 17 8 29 8 36 1 15 -15 -1 -43 -26 -43
-12 0 -38 -6 -56 -14 -19 -7 -43 -17 -54 -21 -30 -12 -48 -7 -72 23 -28 33
-30 62 -5 62 33 0 63 30 60 58 -5 41 -30 37 -60 -10 -26 -41 -29 -43 -80 -43
-29 -1 -62 -6 -72 -12 -18 -10 -15 -14 27 -54 58 -56 62 -77 20 -103 -18 -12
-32 -27 -33 -33 0 -18 36 -16 64 2 29 19 54 10 62 -21 14 -55 -17 -67 -97 -38
l-44 15 -43 -46 c-51 -56 -75 -55 -70 2 2 30 8 39 28 44 l25 7 -28 1 c-48 2
-58 63 -16 103 17 17 18 17 21 0 2 -11 13 -17 33 -18 17 0 38 -4 48 -9 16 -7
18 -3 15 26 -2 27 -7 34 -25 34 -13 0 -28 6 -35 13 -7 7 -18 12 -25 12 -29 0
0 50 30 51 18 1 17 2 -7 9 -15 5 -37 17 -48 27 -16 15 -21 15 -28 3 -6 -8 -10
-23 -10 -33 0 -10 -19 -46 -42 -80 -24 -34 -44 -66 -44 -72 -1 -5 -2 -23 -3
-38 -1 -22 -5 -27 -19 -23 -11 4 -28 -4 -45 -20 -16 -14 -30 -24 -32 -23 -17
19 -56 105 -61 135 -5 33 -2 43 20 63 14 14 26 30 26 37 0 8 10 32 21 54 20
38 23 40 43 27 28 -19 32 -44 7 -52 -16 -5 -19 -12 -14 -40 5 -32 3 -36 -28
-49 -19 -8 -37 -18 -41 -22 -10 -10 22 -33 47 -34 16 0 21 6 20 26 0 20 9 31
40 48 49 28 65 78 34 104 -23 20 -25 60 -4 68 8 3 31 -4 50 -16 20 -12 38 -20
41 -18 2 3 -5 26 -17 52 -11 26 -19 50 -16 52 13 13 60 -24 74 -58 14 -35 19
-38 57 -40 49 -2 129 2 135 7 2 2 -3 11 -11 19 -14 13 -49 14 -113 4 -26 -4
-22 24 11 67 27 36 34 98 9 89 -8 -4 -15 -13 -15 -20 0 -25 -31 -64 -51 -64
-26 0 -24 31 2 53 15 14 20 27 17 55 -2 31 0 36 15 34 9 -2 17 1 18 5 3 30 10
46 25 57 15 11 13 15 -19 35 -45 28 -142 27 -142 -1 0 -17 -45 -58 -64 -58 -6
0 -11 18 -11 40 0 33 -3 40 -19 40 -11 0 -32 -16 -47 -35 -29 -38 -45 -43 -64
-20 -17 20 -66 19 -74 -1 -6 -15 27 -53 47 -54 4 0 7 -6 7 -14 0 -23 -27 -26
-46 -5 -10 11 -24 17 -31 15 -8 -3 -16 1 -19 9 -8 19 -63 20 -70 1 -3 -8 -13
-17 -21 -20 -14 -6 -14 -4 -2 11 27 35 41 46 106 89 36 24 80 46 97 50 17 3
38 13 48 21 9 8 49 22 90 32 l73 17 -65 12 c-36 7 -81 11 -100 11 -19 -1 -57
1 -85 5 -27 4 -55 3 -62 -1z m2954 -49 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-2553 -130 c6 -16 0 -34 -12 -34 -18 1 -32 18 -26 33 8 21 30
22 38 1z m2272 -5 c9 -15 -11 -33 -30 -26 -9 4 -13 13 -10 22 7 17 30 20 40 4z
m-2430 -94 c18 -14 18 -14 -6 -15 -35 0 -52 -14 -44 -33 4 -11 1 -17 -9 -17
-19 0 -31 48 -16 66 15 18 50 18 75 -1z m2631 3 c5 -7 7 -28 4 -45 -5 -25 -9
-30 -20 -23 -12 7 -12 11 -2 23 10 13 7 16 -18 22 -17 3 -35 4 -41 0 -5 -3
-10 0 -10 9 0 26 70 37 87 14z m-2893 -21 c12 -19 3 -32 -19 -24 -17 7 -21 51
-3 45 6 -2 16 -12 22 -21z m3113 7 c5 -17 -15 -37 -31 -31 -14 5 -15 11 -6 27
11 21 31 23 37 4z m-3059 -98 c2 -9 -3 -16 -10 -16 -17 0 -38 20 -38 37 0 18
44 0 48 -21z m1614 -8 c-4 -38 18 -114 33 -111 17 3 17 23 1 23 -9 0 -16 7
-16 15 0 8 3 14 8 13 4 0 15 2 25 7 10 4 19 6 20 4 1 -2 17 -23 35 -46 40 -52
42 -83 6 -83 -30 0 -67 -18 -59 -29 2 -5 23 -9 45 -9 53 -1 57 -5 56 -49 -1
-52 -32 -56 -77 -10 -48 47 -62 50 -95 18 -16 -15 -40 -34 -53 -40 -34 -19
-55 4 -46 51 11 55 17 61 54 42 33 -17 65 -12 59 8 -2 6 -23 18 -46 27 -49 18
-51 27 -17 67 13 16 32 52 41 81 19 60 32 70 26 21z m1399 13 c-13 -23 -38
-34 -47 -20 -8 14 15 39 38 39 15 0 17 -3 9 -19z m-3115 -40 c14 -23 30 -32
83 -46 99 -25 94 -23 86 -48 -5 -12 -8 -37 -8 -54 -1 -34 -20 -45 -31 -18 -3
8 -17 15 -32 15 -17 0 -37 12 -58 34 -37 41 -82 61 -101 46 -6 -5 -15 -7 -18
-3 -11 10 -8 43 4 43 5 0 7 5 4 11 -8 12 23 49 41 49 7 0 21 -13 30 -29z
m3226 12 c10 -9 18 -24 18 -35 0 -10 7 -18 15 -18 8 0 15 -4 15 -9 0 -16 -30
-33 -47 -27 -22 9 -89 -23 -100 -48 -5 -10 -26 -24 -47 -31 -33 -12 -38 -11
-43 3 -4 10 -10 20 -15 23 -5 3 -10 18 -11 33 -2 26 2 29 67 46 53 13 76 25
95 48 31 37 30 36 53 15z m-214 -32 c-3 -22 -7 -26 -33 -23 -55 5 -60 10 -34
35 33 31 71 24 67 -12z m-2786 12 c27 -24 22 -33 -21 -33 -38 0 -59 19 -45 41
9 15 46 10 66 -8z m3058 -3 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10
10 10 6 0 10 -4 10 -10z m-1967 -16 c21 4 30 -1 41 -21 8 -17 16 -22 20 -14 8
12 54 15 80 5 27 -10 18 -84 -10 -84 -9 0 -14 -12 -14 -35 0 -24 -5 -35 -15
-35 -8 0 -15 6 -15 14 0 21 -27 40 -33 24 -3 -7 -6 4 -6 26 -1 31 -4 36 -14
27 -18 -14 -51 13 -61 49 -16 53 -18 65 -8 52 5 -8 20 -11 35 -8z m740 -41
c-2 -6 7 -15 20 -20 22 -8 31 -9 80 -12 34 -1 37 -34 4 -55 -21 -14 -37 -55
-52 -133 l-6 -31 -57 5 c-31 2 -63 6 -69 8 -17 6 -6 126 13 141 11 9 7 15 -17
33 -55 41 -24 82 56 78 17 -1 29 -7 28 -14z m903 -27 c23 -24 16 -36 -20 -36
-41 0 -56 18 -31 37 24 17 33 16 51 -1z m-271 -78 c-17 -54 -22 -143 -8 -163
19 -28 17 -105 -2 -105 -9 0 -15 9 -15 24 0 14 -4 28 -10 31 -5 3 -10 26 -10
51 0 24 -5 44 -10 44 -6 0 -22 12 -36 27 -19 20 -24 36 -22 63 3 34 4 35 53
41 27 3 54 6 59 7 4 1 5 -8 1 -20z m-1805 -112 c7 -29 10 -59 6 -65 -4 -6 -22
-11 -41 -11 -19 0 -45 -4 -59 -10 -23 -8 -26 -7 -32 18 -3 15 -10 40 -14 55
-11 34 -3 57 19 58 9 0 32 6 51 13 19 7 40 9 46 4 6 -4 17 -32 24 -62z m611
53 c7 -14 8 -24 1 -31 -15 -15 -32 0 -32 28 0 30 15 31 31 3z m-116 1 c8 -13
-4 -50 -16 -50 -5 0 -9 14 -9 30 0 30 13 40 25 20z m1418 -37 c-8 -24 -44 -53
-65 -53 -24 0 -22 12 7 44 28 30 68 36 58 9z m237 13 c0 -3 -4 -8 -10 -11 -5
-3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-20 -46 c0 -11 -4 -20 -8
-20 -5 0 -16 -16 -26 -36 l-17 -37 28 6 c43 8 67 -14 59 -55 -4 -18 -8 -49
-10 -69 -1 -19 -6 -41 -10 -47 -12 -19 -36 -14 -46 11 -15 33 -33 61 -47 70
-7 4 -13 19 -13 32 0 14 -13 34 -32 50 -44 36 -52 53 -38 71 16 19 44 19 37
-1 -4 -8 5 -23 20 -35 31 -24 47 -26 39 -4 -7 18 17 70 36 77 23 10 28 7 28
-13z m-2914 -10 c-3 -11 -9 -29 -12 -40 -5 -16 -2 -20 15 -20 12 0 31 -5 42
-10 23 -13 26 -60 3 -60 -20 0 -97 77 -89 90 3 6 0 10 -8 10 -8 0 -18 7 -21
16 -9 24 -8 27 13 21 10 -2 25 -1 32 4 23 14 32 10 25 -11z m2954 6 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3150 -38 c1
-24 55 -73 84 -76 58 -4 62 -34 11 -75 -64 -51 -35 -101 29 -52 41 31 62 28
76 -11 17 -43 -3 -58 -71 -50 -62 6 -79 -5 -113 -71 -27 -55 -37 -46 -70 65
-18 61 -8 98 23 89 24 -6 51 10 45 27 -3 9 6 15 25 19 36 8 39 19 10 48 -26
26 -55 21 -52 -9 3 -28 -1 -28 -22 4 -12 19 -14 29 -5 39 6 7 8 25 4 39 -5 20
-2 26 10 26 9 0 16 -6 16 -12z m1755 2 c-3 -5 -16 -10 -28 -10 -18 0 -19 2 -7
10 20 13 43 13 35 0z m154 6 c-10 -9 -109 -14 -104 -6 4 6 29 10 57 10 28 0
49 -2 47 -4z m-631 -33 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4 33 0 48 -2 33
-4z m1406 -9 c8 -20 -1 -34 -20 -34 -8 0 -14 8 -14 18 0 33 23 44 34 16z
m-719 -25 c224 -27 312 -39 370 -54 28 -7 84 -21 125 -30 66 -16 139 -39 325
-105 89 -31 320 -132 323 -141 2 -5 9 -9 16 -9 20 0 275 -134 363 -191 29 -19
80 -52 115 -74 35 -22 65 -42 68 -45 3 -3 12 -9 22 -15 31 -19 172 -129 203
-159 18 -17 54 -51 81 -76 64 -59 175 -184 264 -296 38 -49 77 -98 86 -109 8
-11 23 -32 32 -47 9 -16 20 -28 24 -28 4 0 8 -5 8 -10 0 -6 18 -35 40 -65 22
-30 40 -56 40 -59 0 -2 21 -40 48 -83 83 -138 172 -308 172 -329 0 -8 4 -14 9
-14 5 0 13 -10 17 -22 3 -13 17 -46 30 -73 29 -62 104 -259 114 -300 4 -16 19
-73 33 -125 39 -150 66 -295 89 -470 17 -140 18 -670 0 -800 -18 -129 -41
-267 -54 -315 -6 -22 -14 -58 -19 -80 -11 -59 -58 -227 -69 -247 -6 -10 -10
-23 -10 -29 0 -12 -74 -205 -113 -294 -32 -74 -145 -293 -157 -305 -3 -3 -14
-21 -24 -40 -21 -38 -107 -172 -116 -180 -3 -3 -21 -27 -39 -55 -153 -221
-494 -562 -716 -715 -27 -19 -52 -38 -55 -41 -12 -14 -100 -69 -109 -69 -6 0
-11 -4 -11 -9 0 -5 -13 -14 -30 -21 -16 -7 -30 -16 -30 -21 0 -5 -4 -9 -9 -9
-5 0 -62 -29 -126 -65 -65 -36 -120 -65 -122 -65 -3 0 -39 -16 -81 -36 -81
-37 -89 -41 -162 -69 -25 -9 -52 -20 -60 -24 -8 -5 -49 -18 -90 -31 -41 -12
-80 -27 -87 -32 -6 -5 -24 -13 -40 -18 -50 -15 198 2 292 20 56 11 96 14 107
9 42 -22 98 -4 98 31 0 26 46 63 70 57 11 -3 20 -1 20 4 0 5 7 9 16 9 13 0 15
-7 11 -30 -6 -27 -4 -30 18 -30 19 0 26 7 31 28 4 15 11 35 16 45 9 17 11 17
38 -3 20 -14 30 -31 32 -53 3 -30 5 -32 43 -31 49 1 139 11 225 24 36 6 109
14 163 20 172 16 247 37 247 70 0 25 21 40 55 40 17 0 35 4 41 10 5 5 37 13
71 16 l61 7 7 46 c6 46 41 131 53 131 19 -1 43 -33 83 -109 24 -46 50 -86 57
-89 16 -6 111 15 160 35 39 16 40 31 7 83 -14 22 -25 44 -25 50 0 5 -6 10 -12
10 -8 0 -9 3 -3 8 6 4 23 25 40 47 16 22 40 48 52 58 125 100 258 167 267 135
8 -27 17 -44 41 -83 14 -22 28 -47 32 -55 3 -8 15 -27 25 -42 10 -14 18 -36
18 -47 0 -12 7 -24 16 -28 15 -6 15 -8 -2 -20 -17 -12 -17 -13 4 -13 12 0 22
4 22 9 0 5 9 11 20 14 11 3 20 1 20 -4 0 -9 9 -8 95 11 183 40 242 55 325 82
221 70 402 183 432 270 6 16 14 28 19 28 5 0 9 23 9 50 0 45 -4 54 -38 88 -44
43 -85 57 -217 74 -138 16 -305 -2 -510 -56 -54 -15 -90 -28 -195 -73 -58 -25
-114 -56 -134 -75 -11 -10 -26 -18 -33 -18 -7 0 -13 -9 -13 -19 0 -11 -4 -21
-9 -23 -13 -4 -90 -71 -170 -147 -64 -60 -147 -100 -162 -78 -4 5 -42 -4 -89
-22 -109 -42 -135 -35 -40 11 66 32 104 59 211 153 60 52 193 138 285 184 278
138 554 201 819 188 167 -9 237 -30 302 -91 18 -17 23 -32 23 -77 0 -33 -6
-63 -15 -75 -8 -10 -15 -27 -15 -36 0 -10 -4 -18 -9 -18 -5 0 -12 -8 -15 -17
-4 -10 -24 -37 -46 -60 -34 -35 -39 -46 -34 -71 5 -24 2 -32 -14 -41 -11 -6
-26 -17 -34 -23 -44 -43 -238 -114 -399 -148 -117 -25 -258 -59 -369 -89 -86
-24 -363 -89 -430 -101 -197 -35 -301 -53 -475 -80 -110 -17 -227 -35 -260
-40 -187 -29 -695 -87 -970 -110 -71 -6 -155 -15 -186 -20 -30 -5 -462 -7
-960 -6 -936 2 -903 1 -1304 46 -49 5 -135 14 -190 20 -249 27 -714 88 -835
110 -30 6 -136 23 -235 40 -274 45 -516 97 -705 152 -19 5 -71 18 -115 28 -44
10 -105 25 -135 33 -30 8 -75 18 -100 22 -74 12 -271 80 -330 115 -72 42 -114
81 -98 91 15 10 -1 43 -43 89 -16 17 -29 35 -29 41 0 5 -4 9 -8 9 -16 0 -46
85 -50 140 -3 48 -1 58 20 77 126 116 438 127 803 28 238 -64 461 -184 647
-347 77 -67 129 -102 186 -124 45 -17 46 -17 64 6 20 25 21 38 9 92 -5 20 -7
39 -4 42 3 2 19 -9 36 -25 29 -28 32 -38 28 -104 -1 -9 -12 -27 -26 -41 -19
-19 -29 -22 -42 -15 -37 20 -199 65 -225 62 -42 -4 -89 24 -169 101 -41 38
-93 86 -116 105 -23 19 -40 40 -37 47 3 8 -3 16 -13 19 -19 6 -74 38 -83 48
-10 10 -115 55 -235 100 -27 11 -59 19 -71 19 -11 0 -24 3 -28 7 -13 13 -116
31 -249 44 -133 13 -253 5 -337 -24 -58 -19 -110 -56 -121 -85 -12 -31 -5
-105 10 -110 6 -2 11 -11 11 -20 0 -20 86 -105 145 -144 119 -80 343 -156 610
-207 33 -7 79 -16 103 -22 45 -10 62 -6 62 14 0 7 9 18 20 25 13 8 20 23 20
45 0 17 7 41 15 51 8 11 15 28 15 38 0 10 5 18 10 18 6 0 10 7 10 15 0 7 7 18
16 23 9 5 17 20 17 34 0 23 2 24 38 18 83 -15 295 -171 314 -230 4 -11 -6 -43
-26 -80 -22 -43 -29 -64 -21 -72 15 -15 141 -48 184 -48 26 0 38 5 38 14 0 32
25 34 58 6 31 -26 32 -29 15 -38 -17 -10 -17 -11 1 -18 10 -4 33 -2 50 4 48
18 72 15 101 -13 28 -25 82 -37 150 -33 22 1 62 -2 89 -6 l49 -9 -24 25 c-13
13 -43 37 -67 52 -36 22 -42 31 -36 49 8 28 13 33 42 42 20 6 25 1 43 -40 15
-33 25 -45 36 -41 8 3 18 6 23 6 5 0 12 9 15 20 9 28 59 27 79 -2 11 -15 12
-25 5 -34 -6 -8 -6 -14 0 -18 5 -3 7 -24 4 -46 -6 -40 -6 -40 28 -40 23 0 41
7 52 21 17 19 20 20 40 5 38 -28 49 -30 63 -11 19 26 65 6 61 -27 -2 -22 2
-23 58 -24 33 0 114 -6 180 -13 66 -8 129 -11 140 -9 l20 5 -20 7 c-143 53
-252 99 -360 151 -114 55 -395 220 -430 252 -11 10 -32 26 -46 35 -27 17 -160
122 -219 173 -63 54 -305 299 -351 355 -25 30 -65 82 -90 115 -24 33 -47 62
-50 65 -16 14 -159 234 -159 246 0 8 -3 14 -8 14 -4 0 -32 48 -62 108 -30 59
-57 109 -60 112 -9 7 -126 289 -140 335 -7 22 -16 47 -20 55 -4 8 -17 51 -30
95 -12 44 -26 91 -30 105 -5 14 -16 59 -25 100 -9 41 -21 95 -27 120 -6 25
-14 72 -19 105 -4 33 -12 92 -17 130 -21 137 -35 392 -29 530 8 191 24 363 36
405 6 19 11 52 11 74 0 21 4 55 9 75 5 20 17 72 26 116 8 44 22 103 30 130 8
28 21 77 30 110 9 33 20 68 26 77 5 10 9 24 9 33 0 8 7 31 16 52 9 21 23 54
31 73 8 19 21 48 29 63 8 16 14 33 14 39 0 16 150 311 176 345 13 17 24 37 24
43 0 7 30 58 68 113 37 56 72 109 77 118 29 46 161 219 234 307 140 167 345
347 539 474 153 100 158 103 202 126 19 10 62 33 95 52 32 18 82 44 110 57 89
44 169 82 206 97 380 162 603 221 1024 271 227 27 265 26 540 -6z m998 -38
c35 -13 34 -26 -2 -41 -46 -19 -75 -10 -79 23 -2 16 2 35 9 43 10 13 14 13 28
0 9 -8 28 -19 44 -25z m-2630 -11 c6 -22 -16 -93 -31 -98 -5 -1 -15 18 -21 44
-11 41 -10 48 6 64 21 20 39 16 46 -10z m91 -16 c11 -28 6 -54 -9 -54 -20 0
-36 41 -23 58 14 16 25 15 32 -4z m2974 -43 c19 -1 15 -59 -5 -69 -25 -13 -52
0 -91 42 l-34 36 59 -4 c32 -2 64 -5 71 -5z m-108 -77 c53 -28 63 -52 36 -89
-13 -18 -15 -18 -62 7 -60 32 -72 48 -56 82 14 32 20 32 82 0z m577 -81 c18
-21 35 -46 39 -55 6 -20 25 -23 41 -7 17 17 100 -27 108 -58 6 -26 -16 -63
-38 -63 -6 0 -18 -6 -26 -14 -20 -17 -112 -48 -120 -40 -3 3 -6 33 -6 67 0 49
-6 73 -28 111 -27 48 -33 76 -20 89 11 12 16 8 50 -30z m-366 -30 l33 -38 -40
-6 c-70 -12 -100 13 -74 62 16 29 45 23 81 -18z m-3963 -12 c72 -22 115 -47
107 -61 -9 -15 -49 -12 -71 5 -10 8 -32 15 -48 15 -32 0 -66 25 -66 47 0 17 3
17 78 -6z m358 -17 l19 -36 -40 -34 c-60 -52 -71 -57 -89 -38 -25 24 -32 65
-13 76 9 4 22 14 29 21 18 17 61 47 69 47 3 0 14 -16 25 -36z m-424 -71 c19
-20 19 -23 5 -23 -9 0 -17 7 -17 16 0 10 -6 14 -14 11 -9 -4 -17 2 -21 13 -3
10 -12 21 -20 24 -20 7 -19 24 3 28 10 2 22 -6 30 -22 7 -14 23 -35 34 -47z
m209 -42 c13 -11 19 -28 17 -43 -3 -20 -9 -23 -53 -26 -50 -3 -95 -30 -95 -57
0 -23 -32 -75 -46 -75 -18 0 -66 104 -62 133 2 14 10 22 21 22 10 0 24 8 31
17 13 18 40 9 62 -20 9 -13 17 -9 49 26 42 48 48 49 76 23z m4057 -92 c-7 -5
-19 -9 -25 -9 -16 0 -17 46 -2 55 5 4 15 19 22 33 11 26 12 25 15 -22 2 -33
-1 -52 -10 -57z m111 22 c20 -20 20 -20 0 -44 -10 -14 -19 -30 -19 -36 0 -6
-9 -11 -20 -11 -23 0 -25 15 -8 70 15 45 20 48 47 21z m289 10 c10 -6 2 -14
-30 -29 -50 -23 -146 -47 -155 -38 -11 10 29 46 51 46 12 0 32 7 46 14 29 16
69 19 88 7z m102 -39 c0 -22 -79 -104 -92 -96 -4 3 -8 13 -7 22 0 11 3 12 6 5
9 -24 24 -13 20 15 -2 15 3 37 11 50 18 28 62 30 62 4z m-5048 -21 c2 -4 23
-19 48 -33 38 -21 45 -30 48 -59 2 -28 9 -37 32 -46 27 -10 30 -16 30 -57 0
-25 -4 -46 -9 -46 -6 0 -23 16 -38 36 -15 21 -42 43 -60 51 -36 14 -45 46 -17
57 34 13 5 16 -46 5 -65 -14 -100 -2 -100 35 0 18 10 29 38 44 41 21 69 27 74
13z m4792 -57 c9 -8 16 -27 16 -42 0 -25 -3 -27 -50 -29 -49 -2 -50 -2 -50 27
0 21 7 33 28 43 36 20 38 20 56 1z m-4563 -55 c34 -34 13 -74 -25 -46 -14 10
-17 21 -12 40 8 32 10 33 37 6z m4683 -4 c6 -9 22 -17 34 -18 20 -1 22 -5 16
-31 -8 -35 -50 -102 -69 -110 -27 -10 -63 143 -38 167 12 12 46 7 57 -8z m406
-1 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z
m-5620 -45 c17 -17 29 -34 26 -36 -8 -8 -65 40 -65 55 -1 18 4 15 39 -19z
m228 -6 c73 -18 84 -27 76 -59 -4 -17 1 -25 22 -36 60 -29 59 -78 -3 -78 -57
-1 -84 -26 -87 -83 -1 -28 -9 -55 -19 -64 -15 -15 -18 -15 -36 1 -20 18 -71
110 -71 128 0 7 5 6 14 -1 11 -9 17 -6 30 14 17 25 50 33 61 15 11 -18 24 -10
46 30 13 22 27 40 33 40 5 0 -1 12 -15 26 -21 22 -30 25 -71 21 -38 -3 -51 0
-72 18 -54 47 -22 57 92 28z m5356 1 c9 -8 16 -21 16 -28 0 -8 10 -20 23 -29
19 -13 20 -18 9 -34 -7 -10 -15 -26 -18 -35 -4 -12 -17 -18 -35 -18 -15 0 -32
-6 -36 -14 -4 -7 -18 -18 -30 -24 -17 -9 -22 -9 -27 4 -11 29 -6 53 14 64 11
6 20 20 20 32 0 36 23 98 36 98 7 0 20 -7 28 -16z m-5506 -80 c24 -36 21 -64
-2 -32 -7 10 -16 15 -19 11 -4 -3 -7 1 -7 10 0 8 -3 22 -6 31 -11 29 11 16 34
-20z m5306 -32 c14 -21 26 -42 26 -47 0 -5 7 -16 15 -25 14 -16 12 -20 -17
-49 -56 -55 -63 -56 -108 -15 -22 20 -40 45 -40 56 0 11 14 37 30 57 17 20 35
42 41 49 15 19 24 15 53 -26z m457 -134 c-5 -24 -12 -33 -26 -33 -29 0 -31 35
-4 56 30 23 38 17 30 -23z m-131 23 c0 -15 -10 -19 -104 -35 -60 -10 -93 -21
-101 -33 -13 -17 -14 -17 -20 0 -16 39 14 55 87 48 10 -1 28 5 40 13 24 17 98
22 98 7z m64 -93 c-27 -31 -55 -55 -84 -70 -43 -22 -53 -36 -34 -46 15 -9 15
-14 -7 -62 -27 -61 -64 -83 -87 -53 -10 13 -12 34 -7 73 6 58 26 90 57 90 18
0 70 30 118 69 14 11 34 21 44 21 19 0 19 0 0 -22z m-5910 -56 c-12 -25 -22
-32 -44 -32 -30 0 -60 22 -60 45 0 14 99 37 113 25 4 -3 0 -21 -9 -38z m5650
16 c5 -15 8 -29 5 -32 -2 -2 -22 -1 -44 3 -28 4 -41 12 -43 25 -9 44 67 48 82
4z m-5411 -12 c50 -34 50 -48 3 -102 -41 -46 -45 -48 -69 -35 -13 7 -30 23
-37 36 -10 19 -8 29 22 72 18 28 38 49 43 49 6 -1 23 -10 38 -20z m-153 -61
c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m-159 -60 c34 -14 34 -14 6 -15 -15 0 -36 -5 -46 -12 -24 -15 -51 -4 -51 22 0
25 41 27 91 5z m160 -37 c4 -15 14 -35 23 -44 9 -8 16 -19 16 -24 0 -13 -30
-40 -44 -40 -8 0 -19 -7 -26 -15 -6 -8 -17 -13 -23 -12 -8 1 -12 -20 -12 -64
0 -41 -5 -72 -14 -82 -12 -16 -15 -16 -41 0 -15 9 -39 33 -51 54 -13 21 -27
39 -31 39 -5 0 -8 7 -8 15 0 12 7 14 26 9 19 -5 25 -4 20 5 -4 6 -16 11 -28
11 -19 0 -108 76 -108 92 0 13 37 9 49 -6 7 -8 24 -17 39 -21 15 -4 34 -13 43
-21 9 -8 33 -15 55 -17 34 -2 39 1 42 21 2 12 14 30 27 38 l24 16 -26 24 c-14
13 -34 26 -44 29 -34 11 -8 26 39 23 40 -3 47 -6 53 -30z m-326 -8 c4 -6 -6
-10 -22 -10 -22 0 -25 2 -13 10 19 12 27 12 35 0z m6290 -32 c19 -8 31 -8 50
0 30 14 75 -2 75 -27 -1 -60 -31 -83 -113 -87 -32 -1 -63 -7 -70 -12 -7 -5
-25 -12 -41 -16 -16 -4 -41 -16 -54 -27 -28 -22 -58 -25 -65 -6 -5 14 37 65
69 83 10 6 24 20 30 32 35 69 65 85 119 60z m-227 -75 c-4 -20 -30 -28 -56
-18 -16 6 -15 8 2 21 28 20 58 18 54 -3z m212 -118 c0 -3 -14 -18 -31 -33 -30
-27 -30 -27 -15 -64 13 -31 14 -38 1 -48 -11 -9 -21 -6 -50 15 -41 32 -43 45
-14 71 12 10 25 29 30 42 7 17 16 22 44 22 19 0 35 -2 35 -5z m-6299 -131 c19
-8 45 -22 59 -29 14 -8 33 -15 42 -15 29 0 71 -74 52 -93 -8 -8 -133 57 -172
90 -24 20 -31 33 -27 46 9 21 5 20 46 1z m202 2 c37 -29 10 -52 -35 -29 -30
15 -36 28 -15 36 20 9 31 7 50 -7z m-139 -188 c20 -23 36 -52 36 -65 0 -13 -2
-23 -5 -23 -2 0 -27 -9 -54 -20 -56 -22 -67 -16 -46 23 8 15 11 27 7 27 -3 0
-1 5 6 12 19 19 14 28 -18 39 -46 17 -39 49 11 49 20 0 37 -12 63 -42z m6346
-100 c8 -24 19 -55 26 -69 12 -23 11 -29 -6 -44 -21 -19 -49 -17 -60 5 -4 8
-17 34 -29 56 -26 52 -27 74 -2 74 11 0 23 5 26 10 13 22 32 8 45 -32z m-6540
-5 c30 -15 68 -30 85 -33 34 -6 54 -39 62 -101 5 -34 3 -40 -18 -48 -35 -13
-66 7 -128 83 -30 36 -57 66 -60 66 -17 1 -53 39 -47 49 12 18 47 13 106 -16z
m-116 -85 c13 -18 22 -36 20 -40 -7 -11 -67 -10 -98 1 -23 9 -24 12 -13 40 16
43 56 43 91 -1z m6771 -68 c4 -6 1 -25 -5 -44 -7 -22 -7 -42 -1 -59 5 -14 7
-27 4 -30 -9 -10 -53 12 -79 40 l-26 28 42 38 c45 39 55 43 65 27z m255 -25
c0 -14 -38 -27 -52 -19 -12 8 -10 32 5 37 19 6 47 -4 47 -18z m-6904 -40 c17
-15 40 -25 60 -26 19 -1 39 -3 44 -4 6 -1 16 -3 23 -4 7 -1 3 -9 -10 -19 -12
-9 -25 -29 -29 -45 -3 -15 -10 -30 -15 -33 -5 -3 -9 -18 -9 -34 0 -37 -14 -45
-51 -30 -27 12 -29 16 -23 49 3 20 13 50 21 66 13 28 12 31 -8 48 -11 9 -27
17 -35 17 -8 0 -14 9 -14 20 0 27 13 25 46 -5z m-58 -131 c-2 -31 -7 -40 -24
-42 -32 -5 -38 25 -13 65 26 42 42 32 37 -23z m6922 36 c0 -9 -82 -90 -92 -90
-3 0 -22 -16 -42 -35 -44 -42 -81 -54 -104 -32 -13 14 -14 22 -3 54 26 78 73
107 175 109 36 1 66 -2 66 -6z m70 -80 c0 -13 -14 -27 -41 -40 -23 -12 -43
-19 -46 -16 -7 7 62 76 76 76 6 0 11 -9 11 -20z m-6972 -74 c47 -25 67 -58 63
-104 0 -7 -9 -12 -19 -10 -10 1 -31 -5 -46 -15 -15 -10 -31 -16 -34 -12 -4 4
-7 30 -8 58 0 29 -4 62 -8 75 -10 27 10 30 52 8z m-258 -31 c0 -8 -7 -15 -15
-15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m7049 -54 c-56 -62
-77 -71 -101 -41 -28 35 -22 47 26 54 24 4 52 13 62 21 11 8 28 15 38 15 15 0
10 -10 -25 -49z m-6902 -3 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-15 -80 c14 -5 35 -7 46 -4 31 10 79 -36 87 -85 8 -48 -6 -69 -47 -69
-19 0 -40 10 -60 30 -16 17 -34 30 -39 30 -11 0 -119 101 -119 111 0 10 97 1
132 -13z m6997 -22 c9 -8 36 -18 60 -23 25 -4 46 -14 49 -21 6 -20 -43 -14
-89 10 l-42 22 -16 -21 c-10 -12 -35 -23 -64 -27 -45 -7 -47 -6 -47 17 0 44
112 76 149 43z m-7141 -70 c15 -8 40 -29 56 -46 33 -36 74 -55 157 -72 32 -6
59 -16 59 -20 0 -20 -35 -67 -50 -68 -3 0 -21 15 -40 33 -41 39 -146 117 -157
117 -17 0 -83 52 -78 61 8 13 21 11 53 -5z m7127 -57 c4 -5 0 -18 -8 -27 -9
-11 -13 -31 -11 -54 3 -20 1 -39 -4 -42 -14 -8 -82 59 -82 82 0 35 88 70 105
41z m243 -53 c3 -13 -1 -17 -14 -14 -11 2 -20 11 -22 22 -3 13 1 17 14 14 11
-2 20 -11 22 -22z m-7118 -178 c1 -17 -34 -38 -54 -32 -75 22 -95 53 -50 77
25 13 30 12 65 -12 21 -14 38 -29 39 -33z m7090 57 c0 -2 -18 -14 -39 -25 -22
-11 -44 -26 -48 -33 -4 -8 -37 -33 -73 -56 -47 -30 -74 -41 -95 -39 -27 3 -30
6 -28 36 2 49 23 92 47 97 55 14 236 29 236 20z m-7250 -46 c0 -14 -17 -10
-23 5 -4 10 -1 13 9 9 7 -3 14 -9 14 -14z m7270 -73 c0 -7 -22 -22 -48 -33
-27 -11 -70 -37 -96 -57 -26 -20 -54 -36 -62 -36 -20 0 -26 -21 -28 -98 -1
-64 -2 -67 -25 -67 -21 0 -26 7 -37 55 -7 30 -13 74 -14 96 l0 41 48 7 c102
13 121 19 149 44 60 53 113 76 113 48z m-7162 -38 c34 -37 44 -74 26 -93 -15
-15 -73 -48 -75 -43 0 2 -2 16 -4 32 -2 15 -11 36 -19 45 -14 15 -13 20 4 49
23 38 39 40 68 10z m-128 -97 c0 -5 3 -16 6 -25 9 -22 -19 -21 -39 2 -10 10
-14 22 -10 25 10 10 43 8 43 -2z m95 -96 c32 -29 52 -100 34 -121 -6 -8 -20
-14 -30 -14 -22 0 -69 20 -87 37 -7 7 -21 13 -30 13 -9 0 -31 14 -49 32 -18
17 -43 32 -55 34 -42 4 -43 6 -13 14 17 4 57 8 90 10 33 1 69 5 80 10 30 13
32 12 60 -15z m6971 -123 c17 -11 26 -65 18 -108 l-7 -37 -38 42 c-44 48 -49
73 -17 95 25 18 29 19 44 8z m-7161 -11 c14 -24 76 -43 158 -48 53 -4 87 -10
87 -17 4 -79 1 -176 -4 -185 -11 -17 -53 -13 -60 5 -3 9 -6 37 -6 63 0 26 -6
54 -12 62 -15 18 -63 49 -77 49 -15 0 -121 63 -121 72 0 11 28 10 35 -1z
m7280 -111 c8 -16 15 -31 15 -35 0 -7 -45 -7 -52 1 -5 5 13 64 19 64 1 0 9
-13 18 -30z m-46 -94 c29 -8 55 -17 58 -19 7 -8 -21 -47 -33 -47 -6 0 -22 -8
-35 -18 -37 -28 -105 -45 -129 -32 -47 25 -41 68 14 107 38 26 55 27 125 9z
m-7004 -118 c19 -9 25 -19 25 -43 0 -18 -7 -38 -16 -45 -8 -8 -22 -29 -29 -47
-17 -40 -31 -36 -40 10 -4 17 -11 37 -16 43 -12 15 28 92 48 94 1 0 14 -5 28
-12z m7075 -42 c0 -2 -7 -6 -15 -10 -8 -3 -15 -1 -15 4 0 6 7 10 15 10 8 0 15
-2 15 -4z m-50 -27 c0 -5 -14 -12 -30 -16 -17 -3 -33 -10 -36 -14 -3 -5 -13
-9 -23 -9 -10 0 -24 -7 -31 -15 -19 -23 -55 -18 -70 10 -22 40 -3 50 99 52 51
0 91 -3 91 -8z m-7166 -45 c22 -22 20 -44 -4 -44 -23 0 -35 22 -26 45 7 19 11
19 30 -1z m169 -108 c10 -11 22 -32 28 -45 21 -53 -60 -83 -113 -42 -15 12
-33 18 -46 14 -27 -7 -38 17 -22 47 30 57 115 71 153 26z m6880 2 c16 -19 16
-23 2 -30 -25 -15 -87 -5 -99 15 -9 15 -7 21 9 33 25 19 64 11 88 -18z m127
-27 c0 -19 -22 -23 -38 -7 -15 15 -14 16 11 16 15 0 27 -4 27 -9z m-182 -70
c40 -25 49 -93 17 -134 -13 -18 -14 -18 -33 1 -30 30 -42 57 -42 96 0 41 26
58 58 37z m-6768 -86 c0 -15 -10 -31 -26 -42 -25 -16 -28 -16 -65 5 -21 12
-51 22 -67 22 -16 0 -36 7 -43 16 -13 15 -9 16 41 11 30 -3 75 -2 100 2 25 5
48 9 53 10 4 0 7 -10 7 -24z m6888 -18 c9 -10 7 -19 -4 -35 -28 -40 -62 -23
-44 22 10 28 31 34 48 13z m-6887 -77 c36 0 39 -2 39 -29 0 -31 -18 -41 -77
-41 -29 0 -33 3 -33 26 0 31 21 75 28 56 2 -7 20 -12 43 -12z m6800 -37 c26
-12 49 -27 51 -35 6 -17 -16 -38 -39 -38 -11 0 -23 -4 -29 -10 -5 -5 -30 -12
-54 -16 -37 -5 -49 -2 -67 16 -13 11 -23 28 -23 36 0 22 47 62 78 67 32 5 26
6 83 -20z m-6739 -123 c-1 -19 -12 -53 -22 -76 -11 -22 -20 -43 -20 -46 0 -8
69 -48 83 -48 7 0 18 -9 25 -21 21 -33 -1 -77 -42 -83 -63 -10 -259 11 -253
28 4 11 84 45 128 53 29 5 60 49 43 61 -13 9 -34 73 -34 102 0 34 39 72 70 68
22 -3 25 -7 22 -38z m6636 -55 c4 -3 2 -9 -4 -13 -6 -4 -19 -32 -29 -62 -26
-75 -37 -87 -70 -72 -28 13 -31 35 -10 72 8 14 14 37 15 51 0 14 3 29 8 33 8
9 80 1 90 -9z m-6774 -51 c3 -9 6 -22 6 -30 0 -17 -62 -44 -102 -44 -28 0 -29
1 -14 23 9 12 33 27 54 33 20 7 36 17 35 23 -4 17 14 13 21 -5z m6666 -173
c37 -36 49 -80 26 -93 -9 -5 -16 -16 -16 -23 0 -8 -6 -15 -12 -15 -43 1 -78
160 -36 160 5 0 22 -13 38 -29z m-6405 -73 c4 -13 18 -40 32 -61 25 -36 25
-38 8 -57 -31 -35 -62 -26 -85 24 -11 25 -20 48 -20 52 0 4 -8 13 -17 20 -16
12 -15 14 15 20 18 4 35 11 38 15 9 16 22 10 29 -13z m6387 -118 c18 -17 26
-29 18 -28 -10 2 -16 -6 -18 -22 -4 -36 -31 -50 -76 -40 -62 14 -85 62 -43 90
57 38 78 38 119 0z m208 -6 c0 -17 -22 -14 -28 4 -2 7 3 12 12 12 9 0 16 -7
16 -16z m-160 -54 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z m-6335 -17 c-12 -27 -26 -134 -19 -144 3 -5 17 -9 32 -9 33 0
82 -51 82 -85 0 -18 -10 -27 -46 -41 -40 -15 -47 -16 -60 -2 -9 8 -18 35 -21
59 -5 40 -27 101 -55 153 -5 10 -6 21 -1 25 5 3 16 18 26 34 21 32 75 41 62
10z m-1215 -23 c-20 -13 -40 -13 -40 0 0 6 12 10 28 10 21 0 24 -2 12 -10z
m7354 -62 c16 -23 13 -38 -9 -38 -17 0 -38 36 -30 50 10 16 23 12 39 -12z
m-39 -82 c24 -10 35 -22 35 -35 0 -27 -38 -28 -79 -3 -23 14 -27 21 -20 35 12
21 20 21 64 3z m-6255 -20 c0 -6 -70 -46 -81 -46 -17 0 -9 15 19 32 25 15 62
23 62 14z m7500 -94 c0 -18 -10 -38 -25 -52 -14 -13 -24 -29 -21 -36 8 -21
-101 -27 -162 -8 -25 7 44 64 114 93 88 37 94 37 94 3z m-1305 -18 l28 -36
-32 -31 c-39 -39 -50 -30 -57 46 -4 51 -2 57 15 57 10 0 31 -16 46 -36z
m-5885 -49 c0 -28 -25 -44 -41 -28 -7 7 -6 17 3 32 18 29 38 26 38 -4z m-307
-52 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m374 -19 c19 -19
16 -27 -23 -51 -38 -25 -52 -27 -72 -14 -10 6 -11 13 -4 27 6 11 13 26 15 34
6 19 66 22 84 4z m5754 -12 c13 -13 20 -52 11 -52 -6 0 -15 -13 -21 -30 -8
-24 -16 -30 -38 -30 -33 0 -73 25 -73 46 0 8 10 30 23 50 20 32 25 34 57 29
19 -4 38 -10 41 -13z m-5958 -3 c20 -5 37 -14 37 -19 0 -14 -9 -12 -55 10 -45
22 -39 25 18 9z m6022 -58 c6 -10 -21 -41 -36 -41 -5 0 -9 11 -9 25 0 18 5 25
19 25 11 0 22 -4 26 -9z m-5735 -47 c0 -29 -19 -31 -51 -5 -19 16 -21 21 -9
24 54 18 60 16 60 -19z m5508 -43 c12 -10 22 -21 22 -25 0 -17 -76 -106 -92
-106 -17 0 -48 35 -48 56 0 15 71 92 86 93 6 1 21 -7 32 -18z m-5408 -31 c0
-5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m25 -20
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m5239
-133 c24 -20 27 -27 18 -43 -6 -10 -12 -14 -12 -9 0 6 -6 -1 -13 -14 -24 -47
-37 -37 -37 28 0 34 3 61 8 61 4 0 20 -11 36 -23z m-154 -98 c0 -7 -8 -18 -17
-25 -17 -13 -17 -13 0 -14 9 0 17 4 17 10 0 16 43 22 73 11 18 -7 26 -15 21
-23 -4 -7 -10 -28 -14 -48 -5 -33 -8 -35 -47 -38 -70 -5 -106 57 -65 113 18
26 32 32 32 14z m-76 -165 c8 -21 -11 -37 -38 -30 -20 5 -26 13 -26 35 0 15 3
30 6 34 7 7 51 -22 58 -39z m-95 -43 c37 -34 38 -42 10 -59 -19 -12 -24 -11
-46 10 -37 34 -48 59 -32 69 23 14 35 10 68 -20z m-106 -95 c27 -21 20 -49
-19 -84 -20 -18 -39 -31 -41 -29 -3 4 11 75 23 115 4 15 14 15 37 -2z m-5823
-94 c191 -71 319 -113 430 -141 19 -5 118 -24 220 -41 374 -65 467 -81 580
-101 185 -32 214 -37 440 -68 270 -37 658 -81 855 -96 44 -4 154 -10 245 -15
91 -5 244 -17 340 -27 249 -25 1247 -26 1480 -1 85 9 209 20 275 23 378 20
789 63 1265 131 170 24 235 35 655 110 110 20 262 46 338 59 77 14 153 29 170
34 18 6 70 21 117 35 94 27 207 67 408 144 74 29 140 52 148 52 8 0 14 -8 14
-18 0 -10 3 -22 7 -26 4 -3 8 -14 9 -22 3 -19 56 -147 64 -154 3 -3 14 -27 24
-55 10 -27 32 -81 50 -120 18 -38 36 -81 40 -95 4 -14 14 -38 21 -55 51 -114
65 -149 65 -158 0 -6 6 -18 14 -26 7 -9 25 -47 40 -86 15 -38 43 -103 62 -144
19 -41 38 -88 41 -105 3 -17 10 -37 16 -43 12 -16 -11 -52 -70 -110 -58 -56
-152 -108 -261 -144 -48 -15 -161 -53 -252 -84 -91 -30 -237 -80 -325 -110
-375 -126 -925 -244 -1515 -325 -215 -30 -290 -39 -455 -55 -82 -9 -163 -17
-180 -20 -16 -2 -75 -7 -130 -10 -55 -4 -140 -11 -190 -17 -132 -15 -2135 -23
-2295 -9 -69 6 -253 22 -410 36 -157 14 -343 32 -415 41 -179 21 -472 64 -590
84 -33 6 -85 15 -115 20 -30 5 -111 20 -180 34 -69 14 -169 34 -223 45 -198
40 -451 107 -657 173 -85 27 -116 39 -185 69 -11 5 -65 22 -120 40 -55 17
-109 34 -120 39 -11 4 -60 20 -109 35 -123 37 -242 97 -301 154 -78 74 -89
102 -61 158 8 15 25 53 37 83 12 30 30 73 40 95 10 22 28 65 39 95 12 30 26
60 31 66 5 6 20 39 33 75 34 90 103 247 126 290 11 20 20 42 20 49 0 7 16 49
36 94 20 44 43 96 50 114 45 104 42 101 72 93 15 -5 124 -44 242 -89z m1532
76 c3 -7 0 -25 -6 -40 -8 -21 -17 -28 -38 -28 -36 0 -37 23 -2 55 30 28 40 31
46 13z m107 -37 c12 -8 12 -13 -2 -35 -20 -29 -57 -66 -68 -66 -11 0 -49 41
-49 52 0 5 10 12 23 17 13 5 29 16 36 25 15 18 39 20 60 7z m4061 -14 c0 -8
11 -19 25 -25 14 -6 25 -17 25 -23 0 -15 -52 -87 -68 -94 -16 -6 -52 12 -69
34 -16 21 -16 76 -1 101 15 24 88 29 88 7z m-186 -112 c20 -15 56 -65 56 -77
0 -5 -6 -8 -14 -8 -7 0 -34 -11 -60 -25 -26 -14 -51 -25 -56 -25 -5 0 -22 19
-39 43 -22 32 -27 47 -20 60 13 22 28 22 45 0 14 -20 12 -22 29 32 6 19 34 19
59 0z m76 -105 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10
-4 10 -10z m267 -25 c3 -9 3 -19 -1 -22 -7 -7 -36 16 -36 28 0 15 30 10 37 -6z
m3093 -315 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z"/>
<path d="M5265 8300 c-33 -4 -91 -11 -130 -14 -98 -10 -120 -15 -108 -27 6 -6
28 -6 59 0 27 5 79 7 116 4 96 -8 238 5 238 22 0 24 -59 29 -175 15z"/>
<path d="M5648 8273 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5695 8250 c11 -5 49 -9 85 -9 45 0 59 3 45 9 -11 5 -49 9 -85 9 -45
0 -59 -3 -45 -9z"/>
<path d="M3540 7603 c-8 -3 -29 -18 -46 -32 -18 -14 -47 -37 -65 -51 -75 -56
-180 -154 -281 -261 -60 -62 -108 -115 -108 -116 0 -3 -76 -98 -101 -126 -9
-11 -25 -34 -61 -92 -7 -11 -19 -24 -25 -28 -7 -4 -13 -15 -13 -22 0 -8 -4
-15 -10 -15 -5 0 -10 -8 -10 -17 0 -15 2 -15 18 3 10 10 31 37 47 59 17 22 35
45 40 52 6 6 21 26 34 43 14 18 37 46 53 62 15 17 28 32 28 34 0 22 393 407
446 437 8 5 12 12 9 18 -4 5 0 9 8 9 7 0 24 11 37 25 25 26 25 27 0 18z"/>
<path d="M7976 7105 c18 -28 24 -31 24 -12 0 7 -9 18 -20 25 -20 12 -21 12 -4
-13z"/>
<path d="M8007 7074 c-12 -12 -8 -24 8 -24 8 0 15 -6 15 -14 0 -7 13 -29 30
-47 16 -19 30 -37 30 -41 0 -7 60 -99 71 -108 3 -3 9 -12 12 -20 4 -8 21 -37
37 -65 17 -27 35 -61 41 -75 6 -13 13 -27 17 -30 4 -3 25 -40 46 -83 22 -43
43 -80 48 -83 4 -3 8 -13 8 -23 0 -10 7 -24 15 -31 8 -7 15 -19 15 -27 0 -8 7
-21 15 -29 8 -9 15 -24 15 -35 0 -10 5 -19 10 -19 6 0 10 -7 10 -16 0 -9 6
-28 14 -42 7 -15 20 -40 28 -57 8 -16 18 -43 22 -59 5 -20 10 -26 18 -18 7 7
6 20 -6 43 -9 18 -16 40 -16 49 0 9 -7 25 -15 36 -8 10 -15 29 -15 40 0 12 -7
27 -15 34 -8 7 -15 20 -15 29 0 28 -182 376 -209 401 -10 9 -31 44 -37 62 -3
9 -12 19 -20 22 -8 3 -14 10 -14 17 0 6 -20 38 -45 70 -25 33 -45 61 -45 64 0
3 -8 13 -17 23 -10 9 -25 28 -33 40 -8 13 -18 20 -23 16z"/>
<path d="M2742 6820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2790 6795 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M8125 6800 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2711 6774 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2760 6759 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2680 6725 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M2655 6687 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17 -12
0z"/>
<path d="M2595 6575 c-22 -44 -45 -85 -50 -91 -6 -6 -19 -37 -30 -68 -11 -31
-23 -56 -27 -56 -4 0 -8 -10 -8 -23 0 -13 -5 -28 -12 -35 -11 -11 -33 -64 -45
-107 -3 -11 -11 -34 -19 -52 -8 -17 -14 -38 -14 -46 0 -9 -4 -18 -9 -21 -5 -4
-12 -25 -16 -48 -4 -23 -11 -49 -16 -58 -7 -14 -17 -49 -40 -145 -5 -22 -13
-49 -18 -60 -15 -35 -57 -257 -66 -345 -23 -228 -26 -347 -15 -530 9 -168 18
-246 44 -405 12 -72 36 -185 51 -240 9 -33 21 -80 27 -105 5 -25 14 -49 19
-54 5 -6 9 -20 9 -33 0 -12 6 -37 14 -55 8 -18 21 -52 30 -75 29 -79 49 -128
63 -163 24 -56 93 -204 104 -220 5 -8 13 -24 19 -35 11 -23 44 -78 117 -200
185 -304 535 -677 829 -885 88 -62 229 -156 244 -163 8 -4 31 -17 50 -28 36
-22 251 -131 305 -154 17 -8 48 -21 70 -30 22 -9 47 -20 55 -24 9 -5 30 -12
48 -15 17 -4 34 -12 37 -17 4 -5 18 -9 33 -9 15 0 36 -7 46 -15 11 -8 29 -15
40 -15 11 0 38 -6 61 -14 99 -35 291 -58 465 -57 74 0 242 2 372 4 131 3 236
1 233 -3 -2 -4 21 -6 53 -5 31 2 60 -2 63 -7 20 -31 609 11 609 44 0 4 12 8
28 8 15 1 38 7 52 15 14 8 35 14 47 15 12 0 34 6 50 14 15 8 53 22 83 32 30
10 66 24 80 31 14 7 33 13 43 13 9 0 17 5 17 10 0 6 9 10 21 10 18 0 50 13 99
41 8 5 22 12 30 15 28 11 145 69 150 74 11 11 72 40 85 40 7 0 15 7 19 15 3 8
10 15 16 15 12 0 114 62 118 72 2 4 8 8 14 8 6 0 27 13 48 30 21 17 41 30 45
30 6 0 83 57 95 70 3 3 34 28 69 55 181 137 438 409 599 630 21 29 119 182
125 195 4 8 21 38 38 65 16 28 37 64 45 80 8 17 18 37 23 45 13 23 44 83 51
100 4 8 14 32 24 53 9 20 23 54 32 75 10 20 21 45 26 55 4 9 15 39 24 65 9 26
22 61 29 77 19 46 43 121 88 280 14 47 31 126 57 255 44 221 61 594 36 780
-33 246 -58 352 -111 475 -24 56 -43 110 -60 165 -9 30 -23 68 -31 83 -8 16
-14 36 -14 46 0 9 -7 26 -15 37 -8 10 -15 29 -15 40 0 12 -7 27 -15 34 -8 7
-15 23 -15 35 0 12 -7 28 -15 35 -8 7 -15 23 -15 36 0 25 -16 41 -22 22 -3 -7
-8 7 -12 31 -8 52 -18 48 -21 -11 -1 -25 3 -48 11 -56 8 -7 14 -24 14 -37 0
-13 5 -27 10 -30 6 -3 10 -16 10 -28 1 -12 8 -40 16 -62 17 -43 25 -72 78
-273 123 -464 125 -1013 7 -1508 -20 -81 -85 -292 -102 -329 -5 -11 -20 -51
-35 -90 -14 -38 -31 -81 -39 -95 -7 -14 -46 -91 -86 -172 -40 -82 -77 -148
-81 -148 -4 0 -8 -5 -8 -10 0 -10 -15 -36 -79 -135 -31 -48 -129 -184 -148
-205 -7 -8 -41 -49 -74 -90 -52 -64 -113 -131 -180 -199 -28 -29 -124 -115
-174 -156 -27 -22 -71 -59 -99 -83 -46 -39 -220 -162 -264 -187 -9 -5 -32 -20
-49 -32 -17 -13 -34 -23 -37 -23 -3 0 -44 -23 -92 -50 -48 -28 -89 -50 -92
-50 -3 0 -42 -18 -86 -39 -45 -22 -108 -50 -141 -62 -33 -12 -79 -30 -102 -41
-23 -10 -51 -18 -63 -18 -11 0 -23 -6 -26 -14 -3 -8 -28 -17 -60 -21 -29 -3
-56 -11 -60 -16 -3 -5 -9 -7 -14 -4 -5 3 -22 0 -37 -8 -15 -8 -48 -17 -72 -20
-24 -3 -47 -10 -50 -15 -3 -5 -38 -12 -78 -15 -40 -3 -81 -11 -92 -16 -11 -6
-49 -11 -85 -11 -37 0 -66 -4 -66 -10 0 -5 -18 -10 -40 -10 -22 0 -40 -4 -40
-9 0 -4 -136 -9 -302 -9 -167 -1 -312 -3 -323 -5 -11 -2 -31 0 -45 5 -37 15
-140 26 -133 15 3 -5 0 -7 -8 -5 -8 3 -13 9 -12 14 1 5 -31 10 -70 12 -39 1
-76 7 -81 12 -6 6 -21 10 -34 10 -14 0 -30 7 -36 15 -7 10 -29 17 -54 18 -42
1 -54 10 -29 21 6 4 -5 4 -26 2 -47 -6 -67 1 -60 21 5 13 -1 14 -37 10 -53 -6
-88 2 -68 15 9 7 4 8 -14 4 -16 -4 -28 -2 -28 4 0 5 -9 10 -19 10 -24 0 -36
16 -19 24 7 3 1 4 -14 1 -17 -3 -28 -1 -28 6 0 6 -7 8 -16 5 -10 -4 -26 3 -42
19 -13 14 -34 26 -46 26 -80 4 -116 20 -116 48 0 7 -10 9 -25 5 -16 -4 -25 -2
-25 5 0 6 -11 16 -25 23 -13 6 -22 14 -20 19 3 4 -9 11 -27 15 -18 3 -39 15
-45 24 -7 10 -31 21 -54 25 -23 4 -48 16 -57 26 -9 10 -22 19 -29 19 -7 0 -13
7 -13 15 0 9 -14 22 -30 29 -17 7 -27 15 -23 19 3 4 -4 7 -16 7 -12 0 -24 7
-27 15 -4 8 -13 15 -20 15 -8 0 -17 7 -20 15 -4 8 -12 15 -19 15 -7 0 -34 20
-59 45 -26 24 -49 44 -52 44 -3 0 -32 24 -65 53 -33 29 -69 58 -79 63 -10 6
-19 14 -19 18 0 4 -22 26 -48 49 -27 22 -49 44 -51 48 -1 4 -35 41 -75 83
-101 106 -149 162 -164 191 -8 15 -17 26 -22 26 -4 0 -27 29 -51 66 -23 36
-56 84 -74 107 -17 23 -43 62 -57 87 -59 104 -72 126 -90 152 -11 14 -19 33
-19 41 0 8 -7 20 -15 27 -9 7 -13 15 -10 18 3 3 -1 12 -8 21 -8 9 -24 41 -37
71 -12 30 -33 78 -45 105 -52 114 -112 294 -160 480 -91 355 -114 774 -65
1180 11 90 11 102 -5 132 -16 30 -17 43 -7 131 19 160 23 185 33 203 5 10 9
29 9 43 0 14 6 40 14 58 8 18 28 78 46 133 18 55 36 104 41 110 5 5 9 19 9 32
0 13 4 23 10 23 5 0 15 18 21 40 7 22 16 40 21 40 4 0 8 11 8 25 0 14 4 25 10
25 5 0 13 12 16 28 7 28 32 73 47 83 5 4 7 17 5 30 -2 19 -11 7 -43 -56z m135
-3130 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z
m50 -69 c0 -8 7 -16 15 -20 8 -3 15 -12 15 -21 0 -8 4 -15 8 -15 5 0 16 -16
25 -35 9 -20 24 -38 32 -41 8 -4 15 -11 15 -18 0 -6 14 -25 30 -43 17 -18 30
-36 30 -41 0 -14 44 -62 58 -62 7 0 12 -8 12 -17 0 -10 9 -26 19 -38 50 -52
81 -87 81 -88 0 -1 34 -38 75 -82 41 -44 75 -83 75 -87 0 -5 5 -8 11 -8 14 0
52 -48 59 -74 3 -12 14 -22 24 -24 14 -3 17 1 11 17 -3 12 -2 21 3 21 14 0 25
-34 13 -38 -6 -2 -11 -8 -11 -14 0 -12 98 -108 111 -108 5 0 9 -7 9 -15 0 -21
-11 -19 -47 13 -17 15 -48 41 -69 57 -20 17 -49 42 -65 58 -15 15 -32 27 -38
27 -6 0 -11 4 -11 10 0 5 -21 29 -46 52 -75 69 -294 333 -294 354 0 4 -11 22
-25 40 -14 18 -25 35 -25 37 0 2 -20 35 -45 73 -25 37 -45 72 -45 76 0 5 -11
26 -25 48 -30 48 -33 68 -5 40 11 -11 20 -26 20 -34z m4790 -766 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-4014 -130 c14 0 47
-41 39 -48 -3 -4 -9 -2 -13 4 -4 5 -24 20 -45 33 -20 12 -34 27 -31 33 4 5 13
3 23 -6 10 -9 22 -16 27 -16z m89 -70 c8 -13 -5 -13 -25 0 -13 8 -13 10 2 10
9 0 20 -4 23 -10z m3675 6 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m-3640 -36 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m40 -30 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10
-3 6 -1 10 4 10 6 0 11 -4 11 -10z m2846 -265 c-8 -8 -23 -15 -33 -15 -14 1
-13 4 7 15 33 19 45 19 26 0z m-176 -55 c12 -8 9 -10 -13 -10 -16 0 -26 4 -22
10 8 12 16 12 35 0z m-117 -37 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m-92 -28 c-1 -5 -16 -10 -34 -11 -17 0 -47 -4 -64 -9 -23 -5 -33 -4
-33 4 0 6 -12 12 -27 13 -16 1 10 5 57 8 101 7 102 7 101 -5z m-221 -37 c0 -6
-3 -9 -6 -5 -4 3 -19 0 -35 -8 -31 -17 -95 -20 -103 -6 -3 5 1 11 9 15 8 3 15
2 15 -3 0 -11 74 -2 78 10 5 13 42 11 42 -3z m73 5 c-7 -2 -19 -2 -25 0 -7 3
-2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M8346 6385 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M8551 6074 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4795 1980 c-4 -6 9 -10 32 -9 30 0 34 2 18 9 -27 12 -43 12 -50 0z"/>
<path d="M8660 7070 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4366 9181 c-18 -7 -18 -8 -2 -14 25 -10 114 -9 120 2 3 5 -11 11
-32 15 -46 7 -62 7 -86 -3z"/>
<path d="M6443 9183 c-13 -2 -23 -9 -23 -14 0 -10 83 -12 119 -2 23 6 23 7 5
14 -20 8 -65 9 -101 2z"/>
<path d="M4828 9163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4535 9145 c17 -8 40 -14 52 -15 12 0 25 -4 28 -10 3 -5 13 -10 21
-10 27 0 98 -38 136 -72 52 -48 148 -90 148 -65 0 29 -61 78 -145 117 -86 40
-144 57 -230 66 l-40 4 30 -15z"/>
<path d="M6310 9150 c-11 -7 -4 -10 25 -10 22 0 47 5 55 10 11 7 4 10 -25 10
-22 0 -47 -5 -55 -10z"/>
<path d="M6230 9133 c-27 -9 -84 -34 -138 -61 -77 -39 -149 -125 -89 -107 41
13 92 39 99 51 13 20 117 85 145 90 32 6 43 12 43 25 0 9 -34 11 -60 2z"/>
<path d="M4565 9056 c-16 -7 -39 -23 -49 -35 -19 -21 -19 -21 3 -21 12 0 30
-4 40 -9 13 -8 23 -2 45 25 39 50 23 67 -39 40z"/>
<path d="M6278 9058 c-14 -12 -12 -16 18 -44 19 -17 34 -27 34 -23 0 5 10 17
22 28 l21 21 -36 15 c-44 18 -40 18 -59 3z"/>
<path d="M5761 9044 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5860 9015 c-22 -23 -22 -27 -8 -41 27 -28 76 13 62 51 -9 22 -27 18
-54 -10z"/>
<path d="M4990 9012 c0 -25 35 -56 55 -49 20 8 19 30 -3 50 -25 23 -52 22 -52
-1z"/>
<path d="M3544 8902 c-51 -42 -72 -178 -34 -222 29 -33 19 -106 -19 -137 l-26
-21 30 -7 c17 -4 50 -14 74 -21 24 -8 46 -13 48 -10 2 2 -5 23 -16 47 -12 23
-21 49 -21 56 0 7 -5 13 -11 13 -6 0 -7 5 -2 13 9 13 11 307 3 307 -3 0 -14
-8 -26 -18z"/>
<path d="M7331 8888 c13 -45 11 -129 -3 -182 -9 -33 -9 -54 -1 -81 7 -23 8
-47 2 -67 -5 -18 -7 -33 -5 -36 2 -2 29 -5 60 -7 63 -3 76 14 29 37 -44 21
-50 64 -19 129 15 30 32 61 38 71 9 12 9 20 0 31 -7 8 -24 36 -38 62 -38 73
-80 100 -63 43z"/>
<path d="M4495 8780 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M6255 8640 c-4 -17 -11 -28 -16 -24 -5 3 -9 -1 -9 -9 0 -8 7 -18 15
-21 24 -9 48 65 26 78 -5 4 -12 -7 -16 -24z"/>
<path d="M3237 8518 c-37 -63 -45 -88 -27 -88 5 0 27 -18 50 -41 40 -40 68
-47 62 -15 -2 11 11 23 42 37 53 23 63 34 41 47 -14 7 -14 13 -4 35 10 22 17
25 37 20 36 -9 24 16 -18 39 -24 12 -45 16 -67 12 -21 -4 -33 -2 -33 5 0 6
-10 11 -23 11 -18 0 -32 -14 -60 -62z m142 5 c0 -10 -4 -26 -7 -36 -6 -15 -10
-16 -25 -5 -16 12 -16 15 -2 36 19 26 35 29 34 5z"/>
<path d="M7565 8568 c-74 -18 -115 -31 -115 -38 0 -3 15 -13 34 -21 33 -15 35
-15 45 8 13 28 22 29 39 3 14 -23 8 -40 -14 -40 -8 0 -14 -4 -14 -10 0 -5 -6
-10 -14 -10 -21 0 -30 -20 -17 -33 6 -6 26 -16 43 -21 25 -8 34 -19 39 -41 4
-16 7 -22 8 -12 0 9 6 17 12 17 6 0 29 16 50 35 22 19 44 35 51 35 9 0 9 3 0
12 -7 7 -12 18 -12 26 0 8 -13 30 -30 49 -16 18 -30 38 -30 43 0 12 -20 11
-75 -2z"/>
<path d="M2716 8515 c-7 -19 -5 -19 30 0 l29 15 -26 0 c-15 0 -29 -7 -33 -15z"/>
<path d="M8156 8515 c11 -8 29 -15 40 -15 12 0 32 -8 45 -17 38 -28 67 -43 84
-43 22 0 18 17 -5 23 -11 3 -20 12 -20 20 0 11 -12 17 -42 19 -25 2 -42 8 -41
14 1 7 -14 12 -38 12 -38 1 -39 0 -23 -13z"/>
<path d="M2600 8494 c0 -3 7 -12 15 -20 11 -12 19 -12 35 -4 26 14 26 24 0 17
-11 -3 -20 -1 -20 4 0 5 -7 9 -15 9 -8 0 -15 -3 -15 -6z"/>
<path d="M2505 8460 c-3 -5 -22 -10 -41 -10 -24 0 -34 -4 -34 -16 0 -12 7 -14
32 -8 30 6 78 31 78 40 0 9 -29 3 -35 -6z"/>
<path d="M2563 8441 c-18 -9 -33 -19 -33 -23 0 -12 28 -9 35 3 4 6 16 14 28
18 11 4 16 10 11 13 -5 3 -24 -2 -41 -11z"/>
<path d="M8405 8440 c-3 -5 3 -11 14 -11 12 -1 26 -2 33 -3 6 -1 13 5 16 12 5
15 -54 17 -63 2z"/>
<path d="M8345 8420 c3 -5 14 -10 25 -10 10 0 20 -7 24 -15 3 -8 13 -15 23
-15 14 0 11 6 -12 25 -29 24 -72 35 -60 15z"/>
<path d="M2348 8388 c-31 -29 -168 -218 -168 -233 0 -3 13 -5 29 -5 23 0 34
-8 50 -34 12 -18 21 -43 21 -55 0 -29 42 -93 75 -115 30 -20 41 -16 28 11 -5
10 -8 72 -8 138 0 115 1 121 28 156 15 20 27 46 27 58 0 19 -3 20 -20 11 -11
-6 -25 -8 -30 -5 -12 8 -13 38 -1 51 13 14 23 54 13 54 -4 0 -24 -15 -44 -32z
m-20 -179 l-3 -34 -18 34 c-13 27 -15 36 -5 42 19 12 30 -5 26 -42z"/>
<path d="M8510 8410 c0 -5 6 -10 14 -10 14 0 29 -61 19 -85 -4 -11 -10 -12
-24 -4 -16 8 -19 6 -19 -12 0 -12 11 -41 25 -64 23 -40 24 -49 21 -174 -1 -72
0 -131 2 -131 16 0 72 54 72 69 0 10 7 24 15 31 8 7 15 25 15 40 0 34 39 80
68 80 33 0 27 20 -28 93 -27 36 -50 70 -50 75 0 16 -102 102 -122 102 -5 0 -8
-4 -8 -10z m119 -159 c9 -6 10 -11 2 -15 -6 -4 -11 -18 -11 -31 0 -41 -18 -37
-22 6 -5 50 1 59 31 40z"/>
<path d="M2491 8391 c-7 -5 -12 -13 -10 -18 2 -6 13 -2 23 8 20 19 12 26 -13
10z"/>
<path d="M2145 8359 c-10 -14 -1 -21 19 -13 9 3 13 10 10 15 -7 12 -21 11 -29
-2z"/>
<path d="M2440 8349 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2086 8324 c-9 -8 -16 -17 -16 -19 0 -8 41 9 51 21 17 20 -15 19 -35
-2z"/>
<path d="M2040 8289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M8915 8270 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M1928 8232 c-13 -15 -31 -31 -39 -34 -25 -9 -102 -105 -148 -184 -38
-66 -41 -75 -41 -146 0 -60 4 -80 20 -101 11 -14 20 -30 20 -35 0 -5 13 -24
30 -41 31 -34 86 -50 114 -34 15 8 13 16 -17 74 -56 104 -68 205 -32 249 26
31 73 133 68 146 -6 16 19 57 54 90 14 13 22 28 18 33 -10 17 -22 13 -47 -17z
m-166 -324 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z"/>
<path d="M7416 8245 c-3 -8 -17 -15 -32 -15 -19 0 -25 -4 -20 -15 3 -10 -1
-15 -11 -15 -12 0 -14 -3 -6 -11 16 -16 93 31 93 58 0 18 -16 17 -24 -2z"/>
<path d="M8940 8248 c0 -7 18 -28 41 -47 30 -26 39 -40 35 -56 -4 -15 13 -53
50 -120 52 -90 56 -101 51 -144 -7 -63 -30 -124 -62 -171 -34 -49 -26 -64 34
-58 35 4 54 12 77 36 17 17 30 33 27 36 -2 2 7 21 20 43 19 29 25 53 26 98 1
55 -4 68 -47 142 -79 136 -252 301 -252 241z m240 -334 c0 -8 -4 -14 -10 -14
-5 0 -10 9 -10 21 0 11 5 17 10 14 6 -3 10 -13 10 -21z"/>
<path d="M2591 8054 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1582 7934 c-24 -36 -23 -56 0 -36 7 6 21 24 31 41 27 45 -2 41 -31
-5z"/>
<path d="M9317 7939 c19 -33 53 -58 53 -39 0 12 -53 70 -64 70 -4 0 1 -14 11
-31z"/>
<path d="M9410 7840 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M1466 7790 c-20 -22 -36 -45 -36 -51 0 -7 -11 -22 -25 -35 -14 -13
-25 -33 -25 -46 0 -13 -18 -59 -40 -103 -24 -48 -40 -94 -40 -115 0 -20 -6
-44 -14 -55 -7 -11 -17 -55 -21 -97 -3 -43 -11 -80 -16 -84 -5 -3 -9 -33 -9
-67 1 -34 1 -86 0 -116 0 -40 4 -57 15 -64 12 -6 14 -17 10 -39 -6 -25 -1 -37
34 -78 23 -27 45 -60 51 -74 6 -16 17 -26 30 -26 22 0 26 -10 9 -27 -15 -15
-7 -58 11 -59 38 -2 40 -4 34 -28 -5 -20 -1 -24 24 -29 19 -4 37 -17 48 -37
11 -18 24 -28 32 -25 8 3 20 5 28 5 24 0 15 27 -11 34 -14 4 -25 11 -25 16 0
6 -6 10 -14 10 -27 0 -27 47 -1 78 2 2 17 -3 33 -12 16 -8 45 -18 63 -21 19
-3 48 -8 65 -11 55 -10 47 13 -9 26 -46 11 -133 46 -147 60 -3 3 -21 13 -41
23 -36 18 -145 121 -175 165 -14 20 -14 23 1 28 9 4 21 2 28 -5 14 -14 47 -14
47 0 0 6 -12 23 -27 38 -89 89 -115 152 -67 159 16 2 34 -11 65 -48 48 -55
122 -110 150 -110 19 0 33 39 22 66 -4 10 -21 31 -40 47 -35 31 -86 114 -98
162 -9 35 -14 180 -7 213 4 20 9 22 51 20 50 -3 61 -11 61 -46 0 -29 28 -79
49 -87 9 -3 21 1 27 9 15 22 34 20 34 -2 0 -11 9 -29 20 -42 11 -13 20 -31 20
-40 0 -13 9 -16 53 -15 28 1 57 3 62 4 17 4 -28 20 -58 20 -35 1 -32 24 4 29
13 2 32 10 42 17 18 12 17 14 -10 29 -15 8 -40 20 -55 27 -30 12 -37 39 -15
57 10 8 24 4 58 -18 31 -21 56 -30 84 -29 l40 0 -40 19 c-22 10 -66 29 -98 41
-91 36 -129 96 -137 217 -5 70 -10 85 -28 98 -12 8 -22 26 -22 40 0 34 -12 30
-54 -16z"/>
<path d="M9419 7818 c0 -2 -2 -15 -3 -30 -1 -15 -10 -34 -20 -41 -13 -10 -20
-33 -26 -88 -7 -79 -33 -140 -72 -175 -12 -11 -62 -35 -110 -54 -49 -18 -88
-34 -88 -36 0 -2 23 -4 51 -4 31 0 48 4 44 10 -3 6 -1 10 6 10 7 0 20 7 30 16
14 12 22 13 38 4 32 -17 25 -39 -19 -56 -22 -9 -45 -25 -50 -34 -8 -15 -5 -20
19 -25 16 -3 35 -13 42 -21 9 -12 6 -14 -20 -15 -72 -3 -76 -15 -9 -26 55 -10
83 -1 74 23 -2 6 6 22 20 35 13 12 24 28 24 35 0 7 5 16 12 20 8 5 9 2 5 -9
-5 -13 -1 -17 17 -17 32 0 54 28 62 78 7 46 39 76 55 51 6 -10 10 -10 23 0 9
7 23 10 32 7 14 -5 16 -16 10 -67 -3 -34 -2 -69 4 -79 7 -12 5 -34 -6 -71 -23
-75 -46 -116 -98 -174 -34 -37 -46 -58 -46 -82 0 -17 6 -33 13 -36 23 -8 89
35 150 99 66 69 93 79 103 39 6 -26 5 -28 -97 -143 -16 -19 -29 -38 -29 -43 0
-15 36 -10 50 6 12 15 50 21 50 8 0 -12 -53 -76 -104 -125 -27 -26 -79 -62
-115 -80 -36 -18 -68 -36 -71 -39 -5 -6 -15 -9 -115 -32 l-60 -13 36 -13 c30
-10 44 -9 80 3 24 9 51 16 59 16 8 0 28 7 44 15 15 8 29 15 30 15 1 0 2 -17 2
-37 -1 -33 -6 -41 -36 -57 -46 -24 -45 -23 -30 -41 18 -22 33 -19 68 15 17 16
42 32 55 36 18 4 23 11 20 24 -3 10 2 29 11 42 9 12 16 30 16 40 0 10 3 18 8
18 4 0 16 16 26 35 11 19 22 35 26 35 17 0 80 123 80 154 0 19 7 40 16 47 13
10 15 28 12 88 -2 47 -8 76 -16 79 -8 2 -12 28 -12 79 0 53 -4 79 -15 89 -8 9
-15 34 -15 57 0 23 -7 51 -15 61 -8 11 -15 30 -15 42 0 23 -29 86 -42 92 -5 2
-8 12 -8 22 0 10 -6 25 -13 32 -8 7 -17 26 -21 41 -3 15 -11 27 -16 27 -6 0
-10 8 -10 18 0 26 -95 119 -101 100z"/>
<path d="M1770 7245 c0 -10 10 -15 27 -15 26 0 26 1 7 15 -25 19 -34 19 -34 0z"/>
<path d="M9130 7240 c-13 -8 -12 -10 3 -10 9 0 17 5 17 10 0 12 -1 12 -20 0z"/>
<path d="M1820 6944 c-8 -24 -9 -39 -2 -46 17 -17 32 1 32 37 0 47 -16 52 -30
9z"/>
<path d="M9080 6926 c0 -40 14 -53 36 -35 11 9 18 9 26 1 7 -7 25 -12 41 -12
40 0 30 23 -14 31 -18 4 -40 17 -48 28 -21 33 -41 26 -41 -13z"/>
<path d="M9520 6914 c0 -10 -5 -13 -99 -59 -34 -16 -61 -33 -61 -37 0 -16 46
-7 85 17 22 14 47 28 55 32 30 14 43 34 31 46 -6 6 -11 7 -11 1z"/>
<path d="M1740 6901 c-12 -9 -12 -11 2 -16 21 -8 48 -3 51 10 3 14 -34 17 -53
6z"/>
<path d="M1454 6865 c48 -37 88 -51 106 -40 13 8 -2 18 -79 54 -66 32 -78 25
-27 -14z"/>
<path d="M2024 6842 c-5 -4 -20 -24 -33 -44 -15 -26 -30 -38 -45 -38 -19 0
-21 -5 -21 -45 0 -25 3 -45 7 -45 14 0 37 31 43 60 4 16 11 30 17 30 15 0 69
62 65 74 -4 13 -20 17 -33 8z"/>
<path d="M8883 6833 c-4 -11 1 -22 15 -31 12 -8 22 -21 22 -28 0 -8 4 -14 10
-14 5 0 21 -17 34 -39 15 -23 38 -43 57 -50 40 -14 72 -14 64 -1 -3 6 -15 10
-26 10 -12 0 -19 7 -19 19 0 10 -7 21 -15 25 -8 3 -15 12 -15 20 0 7 -11 17
-25 20 -14 4 -32 20 -41 39 -25 48 -49 60 -61 30z"/>
<path d="M1298 6513 c-64 -54 -76 -84 -30 -80 44 3 92 51 92 92 0 23 -25 18
-62 -12z"/>
<path d="M9590 6522 c0 -23 59 -89 80 -89 8 0 22 -2 30 -4 50 -13 -35 90 -87
106 -18 5 -23 2 -23 -13z"/>
<path d="M1451 6487 c-12 -19 -21 -41 -21 -49 0 -8 -7 -23 -15 -34 -10 -13
-14 -45 -15 -100 0 -71 4 -88 29 -135 27 -51 31 -54 67 -55 42 0 84 -18 84
-36 0 -7 -14 -9 -40 -5 -35 4 -40 2 -40 -14 0 -17 8 -19 70 -19 54 0 70 3 70
14 0 8 -9 18 -20 21 -11 3 -20 11 -20 16 0 5 -7 9 -16 9 -9 0 -14 6 -11 13 2
6 -9 26 -25 43 -47 50 -65 148 -56 308 4 61 -12 69 -41 23z"/>
<path d="M9452 6466 c0 -25 5 -52 12 -61 10 -14 8 -38 -9 -122 -19 -91 -26
-109 -54 -135 -17 -16 -29 -33 -27 -37 3 -4 -13 -18 -35 -30 -21 -12 -37 -27
-34 -32 4 -5 38 -9 76 -9 70 0 70 0 84 33 8 19 21 40 30 47 36 30 55 87 57
173 3 69 0 88 -14 104 -10 11 -18 31 -18 46 0 23 -39 67 -60 67 -4 0 -7 -20
-8 -44z m-47 -386 c-3 -5 -16 -10 -28 -10 -18 0 -19 2 -7 10 20 13 43 13 35 0z"/>
<path d="M1220 6396 c0 -7 -6 -19 -14 -25 -17 -14 -56 -133 -56 -168 0 -14 5
-34 12 -46 6 -12 21 -49 32 -82 l21 -60 75 -3 75 -2 -27 26 c-14 15 -37 28
-50 29 -36 4 -38 4 -38 15 0 6 9 10 20 10 23 0 23 -3 5 80 -16 74 -19 142 -6
156 12 13 22 62 14 67 -5 2 -20 7 -35 11 -21 5 -28 3 -28 -8z"/>
<path d="M9668 6394 c-8 -7 8 -62 22 -79 13 -15 5 -95 -16 -170 -13 -46 -13
-51 3 -62 16 -12 15 -13 -3 -13 -11 0 -33 -14 -50 -31 l-29 -31 45 3 c25 2 51
0 57 -4 29 -18 63 23 78 93 4 19 14 45 22 57 21 31 10 99 -29 180 -27 58 -32
63 -62 63 -18 0 -35 -3 -38 -6z"/>
<path d="M1041 6179 c-1 -15 -8 -29 -16 -32 -10 -4 -15 -26 -17 -74 -3 -61 -5
-68 -25 -71 -28 -4 -37 -31 -17 -53 9 -10 13 -25 9 -37 -9 -29 11 -36 36 -13
38 35 60 183 41 266 -8 31 -10 34 -11 14z"/>
<path d="M9910 6187 c-11 -99 -10 -168 1 -188 7 -13 14 -34 14 -47 1 -29 30
-62 55 -62 22 0 26 10 9 27 -14 14 -2 34 18 31 9 -2 17 2 19 7 7 19 -15 39
-43 40 -28 2 -28 3 -31 71 -3 87 -8 110 -27 118 -8 3 -15 4 -15 3z"/>
<path d="M763 5966 c-36 -34 -45 -88 -19 -103 8 -4 16 -24 18 -43 l3 -35 69
-3 c87 -4 105 2 83 26 -23 25 -38 70 -27 77 20 12 10 36 -12 33 -18 -2 -22 -9
-20 -28 1 -14 7 -26 12 -28 16 -6 11 -32 -5 -32 -9 0 -29 7 -45 15 -37 19 -41
55 -7 55 21 1 21 1 3 15 -17 13 -18 18 -8 40 15 32 15 32 0 37 -7 2 -28 -10
-45 -26z"/>
<path d="M10140 5983 c0 -5 7 -17 15 -27 20 -26 19 -31 -7 -44 -19 -9 -20 -11
-5 -13 9 -1 22 -2 27 -3 13 -1 11 -16 -5 -39 -16 -23 -85 -36 -85 -16 0 8 9
20 20 27 28 17 26 42 -4 42 -21 0 -24 -4 -23 -35 1 -21 -7 -42 -18 -55 -18
-20 -18 -23 -4 -33 10 -8 39 -10 80 -7 63 5 63 5 74 42 5 20 13 40 18 44 27
27 -22 124 -64 124 -10 0 -19 -3 -19 -7z"/>
<path d="M660 5730 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M10280 5730 c0 -5 14 -10 31 -10 17 0 28 4 24 10 -3 6 -17 10 -31 10
-13 0 -24 -4 -24 -10z"/>
<path d="M800 5700 c6 -11 31 -30 55 -41 25 -12 59 -39 76 -60 21 -27 39 -39
56 -39 14 0 42 -13 62 -29 20 -15 41 -30 48 -32 14 -5 253 -238 253 -248 0 -3
9 -12 19 -18 23 -14 55 -81 65 -135 4 -21 13 -50 21 -65 8 -15 15 -33 15 -40
0 -6 16 -42 37 -80 104 -194 163 -313 163 -329 0 -14 3 -15 18 -3 14 11 15 11
5 -1 -18 -20 -16 -48 7 -80 10 -15 25 -49 31 -76 7 -27 22 -83 35 -124 24 -81
27 -79 39 30 4 41 -1 79 -20 153 -14 53 -25 110 -25 125 -1 48 -35 153 -100
307 -60 142 -144 314 -159 325 -3 3 -14 17 -23 31 -20 31 -95 115 -163 182
-120 117 -401 266 -503 267 -20 0 -21 -3 -12 -20z"/>
<path d="M10090 5706 c-45 -13 -133 -51 -201 -87 -20 -10 -48 -25 -61 -32 -92
-48 -321 -260 -351 -324 -6 -13 -13 -23 -17 -23 -12 0 -121 -227 -190 -399
-48 -120 -70 -195 -76 -268 -2 -28 -11 -70 -19 -93 -15 -43 -21 -212 -9 -245
8 -19 35 57 54 147 6 32 22 77 34 100 13 24 28 61 35 83 7 22 19 54 26 70 7
17 20 46 29 65 8 19 31 64 52 100 76 135 94 171 94 188 0 9 6 29 14 44 8 14
17 46 21 70 4 24 18 57 31 75 13 17 24 36 24 41 0 15 240 252 255 252 7 0 34
20 60 45 33 32 52 44 65 40 13 -4 37 10 80 49 34 30 71 58 83 62 25 7 57 33
57 46 0 11 -36 9 -90 -6z"/>
<path d="M526 5651 c-19 -20 -26 -38 -26 -68 0 -86 56 -132 170 -136 84 -4 94
6 49 46 -20 17 -42 42 -48 54 -7 13 -30 32 -52 44 -36 18 -40 23 -34 48 9 41
-25 48 -59 12z"/>
<path d="M10383 5635 c2 -35 -1 -39 -28 -46 -17 -5 -37 -18 -45 -31 -8 -12
-30 -37 -48 -54 -23 -22 -33 -39 -30 -55 4 -27 32 -36 54 -18 9 7 35 13 58 14
26 0 50 7 60 18 9 9 23 20 29 24 28 17 55 64 50 86 -7 34 -36 87 -48 87 -5 0
-20 3 -32 6 -21 6 -22 3 -20 -31z"/>
<path d="M664 5002 c-16 -10 -26 -49 -17 -66 6 -11 13 -8 34 14 20 23 23 31
13 44 -13 18 -15 19 -30 8z"/>
<path d="M10282 4987 c-7 -8 -1 -23 19 -46 23 -28 30 -31 34 -18 4 8 5 26 3
38 -4 26 -42 44 -56 26z"/>
<path d="M785 4840 c-4 -6 5 -10 20 -10 15 0 24 4 20 10 -3 6 -12 10 -20 10
-8 0 -17 -4 -20 -10z"/>
<path d="M10155 4840 c-4 -6 5 -10 19 -10 14 0 26 5 26 10 0 6 -9 10 -19 10
-11 0 -23 -4 -26 -10z"/>
<path d="M1028 4143 c-44 -5 -50 -18 -13 -27 14 -4 25 -11 25 -16 0 -6 7 -10
15 -10 27 0 94 -49 110 -79 8 -17 15 -43 15 -60 0 -16 4 -32 9 -35 5 -3 12
-44 16 -91 7 -88 20 -117 30 -68 4 15 10 36 16 45 22 41 0 164 -38 209 -7 8
-13 18 -13 22 0 11 -84 86 -96 87 -7 0 -14 7 -18 15 -3 8 -9 14 -13 13 -4 -1
-25 -3 -45 -5z"/>
<path d="M9882 4135 c-4 -6 -20 -19 -36 -29 -37 -24 -74 -66 -104 -120 -20
-36 -23 -52 -20 -136 2 -79 7 -100 24 -122 l21 -27 7 46 c3 25 6 79 6 120 0
102 23 167 69 199 19 13 47 24 61 24 21 0 60 29 60 44 0 10 -82 11 -88 1z"/>
<path d="M2 3379 c2 -96 6 -124 18 -130 8 -5 60 -34 115 -65 114 -65 220 -105
283 -107 38 -2 48 2 73 31 34 38 35 52 9 110 -30 67 -113 141 -214 190 -79 39
-237 92 -272 92 -12 0 -14 -22 -12 -121z"/>
<path d="M0 3140 c0 -67 1 -70 23 -70 13 0 57 -11 97 -25 40 -14 80 -25 87
-25 7 0 31 -12 53 -26 28 -19 40 -34 40 -50 0 -23 3 -24 75 -24 l74 0 21 46
c11 26 20 58 20 71 0 24 -1 25 -67 18 -38 -4 -76 -11 -85 -16 -9 -5 -36 -9
-59 -9 -52 0 -159 60 -210 117 -18 21 -37 37 -41 36 -4 -2 -8 4 -8 12 0 8 -4
15 -10 15 -6 0 -10 -30 -10 -70z"/>
<path d="M9865 3170 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M9900 3130 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M513 1885 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M458 1713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
